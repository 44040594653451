import { useOutletContext } from "react-router-dom";
import { ExpenseLine } from "@apacta/sdk";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useMutation } from "@tanstack/react-query";
import { ConfirmationDialog } from "~/lib/ui/dialog";
import { Button, Dialog, getIcon, LabelInput } from "~/lib/ui";
import { roundToDecimal } from "~/lib/utils/number";
import { NumberInput } from "~/lib/ui/form-elements";

export default function OverviewExpenseLinePage() {
  const api = useAPI();
  const { expenseLine } = useOutletContext<{ expenseLine: ExpenseLine }>();
  const { t } = useTranslation();

  const addAsFavoriteMutation = useMutation({
    mutationFn: () =>
      api.createProduct({
        createProductRequest: {
          expenseLineId: expenseLine.id,
        },
      }),
  });

  return (
    <>
      <TabHeading
        actionArea={
          <Dialog
            trigger={
              <Button variant="tertiary" tabIndex={0}>
                {t("products:expense_lines.add_to_my_products")}
              </Button>
            }
            render={({ onClose }) => (
              <ConfirmationDialog
                onClose={onClose}
                onSubmit={async () => {
                  await addAsFavoriteMutation.mutateAsync();
                  onClose();
                }}
                variant="success"
                Icon={getIcon("success")}
                title={t("products:confirm_add_as_favourite.title")}
                description={t("products:confirm_add_as_favourite.description")}
              />
            )}
          />
        }
      >
        {t("products:product_overview")}
      </TabHeading>
      <div className="flex-1">
        <div className="mb-2 text-gray-500">{t("projects:project_data")}</div>
        <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
          <div className="flex flex-1 flex-col gap-6">
            <div className="bg-whitemd:flex-row fle x-col flex gap-6">
              <div className="flex flex-1 flex-col gap-6 md:basis-1/4">
                <LabelInput
                  label={t("products:number")}
                  disabled={true}
                  value={expenseLine.productNumber ?? ""}
                />
              </div>
              <div className="flex flex-1 flex-col gap-6 md:basis-3/4">
                <LabelInput label={t("common:name")} disabled={true} value={expenseLine.text} />
              </div>
            </div>
            {expenseLine.vendorProduct && (
              <LabelInput
                label={t("products:vendor.category_number")}
                disabled={true}
                value={expenseLine.vendorProduct.productCategoryNumber}
              />
            )}
            <LabelInput
              label={t("common:barcode")}
              disabled={true}
              value={expenseLine.barcode ?? ""}
            />
            <LabelInput
              label={t("common:vendor")}
              disabled={true}
              value={expenseLine?.expense?.vendor?.name}
            />
            {expenseLine.vendorProduct && (
              <LabelInput
                label={t("common:description")}
                disabled={true}
                value={expenseLine.vendorProduct.description}
              />
            )}
          </div>
        </div>
        <div className="mb-2 mt-5 text-gray-500">{t("common:pricing")}</div>
        <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
          <div className="flex flex-1 flex-col gap-6">
            <div className="bg-whitemd:flex-row fle x-col flex gap-6">
              <div className="flex flex-1 flex-col gap-6 md:basis-1/2">
                <NumberInput
                  label={t("products:latest_buying_price")}
                  disabled={true}
                  defaultValue={roundToDecimal(expenseLine.buyingPrice ?? 0)}
                  className="text-right"
                />
              </div>
              <div className="flex flex-1 flex-col gap-6 md:basis-1/2">
                <NumberInput
                  label={t("common:sales_price")}
                  disabled={true}
                  defaultValue={roundToDecimal(expenseLine.sellingPrice ?? 0)}
                  className="text-right"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
