import { useNavigate, useOutletContext } from "react-router-dom";
import { CreateProductRequest, CreateProductRequestProductRuleEnum } from "@apacta/sdk";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useMutation } from "@tanstack/react-query";
import { useToasts } from "~/lib/toast/use-toasts";
import TextInput from "~/lib/ui/form-elements/text-input";
import { ProductRulesSelect } from "~/pages/products/_cmp/product-rules-select";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { OutletContext } from "../[id]";
import { Button } from "~/lib/ui";

export default function OverviewProductPage() {
  const api = useAPI();
  const { product, refetch } = useOutletContext<OutletContext>();
  const { t } = useTranslation();
  const toast = useToasts();
  const navigate = useNavigate();
  const [{ referrer }] = useTypedSearchParams<{ referrer: string }>();

  const { register, isModified, isValid, onChange, getValue, getValues, resetInitialValues } =
    useFormState({
      schema: {
        name: z.string().max(255),
        number: z.string().max(255).optional(),
        barcode: z.string().max(255).optional(),
        unit: z.string().optional(),
        product_rule: z.string().nullable(),
      },
      initialValues: {
        name: product.name,
        number: product.productNumber ?? undefined,
        barcode: product.barcode ?? undefined,
        unit: product.origin ?? undefined,
        product_rule: product.productRule ?? null,
      },
    });

  const editProductMutation = useMutation({
    mutationFn: (args: CreateProductRequest) =>
      api.editProduct({ productId: product.id, createProductRequest: args }),
    onSuccess: async () => {
      toast.showTemplate("CHANGES_SAVED", { timeout: 10000 });
      resetInitialValues(getValues());
      await refetch();
      if (referrer) {
        navigate(referrer);
      }
    },
  });

  function handleSave() {
    editProductMutation.mutate({
      name: getValue("name"),
      productNumber: getValue("number"),
      barcode: getValue("barcode"),
      productRule: getValue("product_rule") as CreateProductRequestProductRuleEnum,
    });
  }

  return (
    <div className="flex w-full flex-col gap-12">
      <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
        <h2 className="m-0">{t("products:product_overview")}</h2>
        <div>
          <Button
            onClick={handleSave}
            variant="tertiary"
            disabled={!isValid || editProductMutation.isPending || !isModified}
          >
            {t("common:save_changes")}
          </Button>
        </div>
      </div>
      <div className="flex-1">
        <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
          <div className="flex flex-1 flex-col gap-6">
            <TextInput {...register("name")} label={t("common:name")} />
            <ProductRulesSelect
              {...register("product_rule")}
              initialProductRule={getValue("product_rule") ?? "NONE"}
              onChange={(productRuleValue) => {
                onChange("product_rule", productRuleValue);
              }}
            />
            <div className="bg-whitemd:flex-row fle x-col flex gap-6">
              <div className="flex flex-1 flex-col gap-6 md:basis-1/4">
                <TextInput {...register("number")} label={t("products:number")} />
              </div>
              <div className="flex flex-1 flex-col gap-6 md:basis-3/4">
                <TextInput {...register("barcode")} label={t("common:barcode")} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
