import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import { useAPI } from "~/lib/api";
import { CACHE_PROJECTS } from ".";
import { PageLayout } from "~/lib/ui/page-layout";
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { PageSpinner } from "~/lib/ui/page-spinner";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { useTranslation } from "react-i18next";

import { useProjectParams } from "./_cmp/use-project-params";
import { Suspense, useCallback, useState } from "react";
import MergeProjectModal from "~/pages/projects/_cmp/merge-project-modal";
import ProjectStatusChange from "~/pages/projects/_cmp/project-status-change";
import { useFeatureFlags } from "~/lib/feature-flags";
import { InlineProjectDetails } from "./_cmp/inline-project-details";
import { linkToNewInvoice, linkToOffer, linkToProject } from "~/lib/utils";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { IGetProject200Response } from "@apacta/sdk";
import { TabHeaderItem } from "~/lib/ui/tabs/tab-header";
import { FilterGroupActivities } from "~/lib/ui/filters/filter-group-activities";
import { getIcon } from "~/lib/ui/icons/icon";
import { LinkedButton } from "~/lib/ui/buttons/linked-button";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { Dialog } from "~/lib/ui";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import { FilterGroupBoolean } from "~/lib/ui/filters/filter-group-boolean";
import { MarkInvoicedDialog } from "./[id]/_cmp/mark-invoiced-dialog";
import { useToasts } from "~/lib/toast/use-toasts";
import { DateRangePopover } from "~/lib/ui/calendar/date-range-popover";

export type ProjectType = IGetProject200Response["data"];

export default function ProjectDetailPage() {
  const { id } = useParams<{ id: string }>();
  const { setPeriod, endDate, startDate, isInvoiced, activities, setSearchParams } =
    useProjectParams();
  const queryClient = useQueryClient();

  const [showMarkInvoicedDialog, setShowMarkInvoicedDialog] = useState(false);
  const [previewFile, setPreviewFile] = useState<Blob>();

  const [mergeDialogOpen, setMergeDialogOpen] = useState(false);

  const api = useAPI();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToasts();
  const flags = useFeatureFlags();

  const projectQ = useQuery({
    queryKey: [CACHE_PROJECTS, "detail", id],
    queryFn: () => api.iGetProject({ projectId: id as string }),
  });

  const deleteProjectMutation = useMutation({
    mutationFn: () => api.iDeleteProject({ projectId: id as string }),
    onSuccess: () => {
      navigate("/projects");
    },
  });

  const { pathname } = useLocation();

  function handlePeriodChange(start?: Date, end?: Date) {
    setPeriod(start, end);
  }

  const handleDownloadPDF = useCallback(async () => {
    const res = await api.viewAsPDFRaw({
      projectId: id as string,
      dateFrom: startDate,
      dateTo: endDate,
      activityIds: activities,
    });
    const blob = await res.raw.blob();
    setPreviewFile(blob);
  }, [startDate, endDate, activities]);

  const handleMarkAsInvoiced = useCallback(async () => {
    try {
      await api.markAsInvoiced({
        projectId: id as string,
        dateFrom: startDate,
        dateTo: endDate,
        activityIds: activities,
      });
      toast.showTemplate("CHANGES_SAVED");
    } catch (err) {
      toast.showTemplate("UNEXPECTED_ERROR");
    }
  }, [startDate, endDate, activities]);

  const projectParams = useProjectParams();
  const kpiQ = useSuspenseQuery({
    queryKey: [CACHE_PROJECTS, id, "kpiData", projectParams],
    queryFn: () =>
      api.projectGetKpiCardsData({
        projectId: id as string,
        dateAfter: projectParams.startDate,
        dateBefore: projectParams.endDate,
        activityIds: projectParams.activities,
        isInvoiced: projectParams.isInvoiced,
      }),
  });

  const kpiData = kpiQ.data?.data;
  if (projectQ.isLoading || !projectQ.data?.data) {
    return <PageSpinner />;
  }
  const project = projectQ.data.data;

  let pageTabs: Array<TabHeaderItem> = [
    {
      id: "overview",
      label: t("projects:overview"),
      to: "./",
      Icon: getIcon("entityOverview"),
    },
    {
      id: "registrations",
      label: t("common:registration", { count: 2 }),
      to: "registrations",
      Icon: getIcon("registration"),
    },
    {
      id: "wall",
      label: t("projects:wall", "Wall"),
      to: "wall",
      Icon: getIcon("wall"),
    },
    { id: "files", label: t("common:files"), to: "files", Icon: getIcon("files") },
    {
      id: "master-data",
      label: t("projects:master_data"),
      to: "data",
      Icon: getIcon("masterData"),
    },
  ];

  pageTabs = pageTabs.filter((tab) => {
    if (tab.to === "tasks" && !flags.has("cp3.planner")) return false;
    return true;
  });

  const projectTypeName = (() => {
    switch (project.projectType) {
      case "fixed_price":
        return t("projects:fixed_price");
      case "hourly":
        return t("projects:variable_price");
      case "offer":
        return t("projects:offer_project");
      case "estimate":
        return t("projects:offer_estimate_project");
    }
  })();

  const offerLink = project.offerId ? linkToOffer(project.offerId) : undefined;

  return (
    <>
      <Dialog
        open={mergeDialogOpen}
        onOpenChange={setMergeDialogOpen}
        className="md:max-w-4xl"
        render={({ onClose }) => (
          <MergeProjectModal onClose={onClose} targetProject={project} onSubmit={onClose} />
        )}
      />
      <PageLayout
        title={projectQ.data?.data.fullName || ""}
        titleHref={{
          pathname: linkToProject(project.id),
          search: location.search, // Preserve filters
        }}
        onBackClick={() => navigate(-1)}
        renderDescription={() => (
          <div>
            <div className="flex gap-4">
              <div className="inline-flex items-center rounded-md border bg-slate-600 px-2 py-1 text-xs text-white">
                <OptionalLink to={offerLink} className="hover:text-white">
                  {projectTypeName}
                </OptionalLink>
              </div>
              <DateRangePopover
                allowUnset
                dateFrom={startDate ? new Date(startDate) : undefined}
                dateTo={endDate ? new Date(endDate) : undefined}
                onChange={(range) => handlePeriodChange(range.from, range.to)}
              />
              <FilterGroupActivities
                value={activities}
                onConfirmSelection={(selection) => {
                  setSearchParams(
                    "activities",
                    selection.map((a) => a.id)
                  );
                }}
                onClear={() => setSearchParams("activities", undefined)}
              />
              <ProjectStatusChange
                project={project}
                initialStatusId={project.projectStatusId}
                onChange={() =>
                  queryClient.invalidateQueries({
                    queryKey: [CACHE_PROJECTS, id],
                  })
                }
              />
              <FilterGroupBoolean
                name={t("projects:filters.invoiced", "Invoiced")}
                value={isInvoiced}
                onClear={() => setSearchParams("isInvoiced", undefined)}
                onConfirmSelection={(selection) => {
                  setSearchParams("isInvoiced", selection);
                }}
              />
            </div>
            <InlineProjectDetails project={project} />
          </div>
        )}
        renderActions={() => (
          <div className="flex flex-row items-center gap-2">
            <>
              <LinkedButton
                to={linkToNewInvoice({
                  projectId: project.id,
                  from: startDate,
                  to: endDate,
                  notInvoicedAmount: kpiData.notInvoiced,
                  activities: activities,
                })}
                Icon={getIcon("invoice")}
                variant="secondary"
              >
                {t("finance:new_invoice", "New invoice")}
              </LinkedButton>
              {flags.has("cp3.forms") && (
                <LinkedButton
                  Icon={getIcon("registration")}
                  variant="secondary"
                  to={linkToProject(project.id, {
                    subPage: `registrations/new`,
                  })}
                >
                  {t("forms:new_form")}
                </LinkedButton>
              )}
              <ActionButtons
                dropdownClassName="bg-white p-2"
                collapseAt={0}
                actions={[
                  {
                    Icon: getIcon("delete"),
                    label: t("common:delete"),
                    mustConfirm: true,
                    onClick: () => deleteProjectMutation.mutateAsync(),
                    //onExecute: (p) => api.iDeleteProject({ projectId: p.id }),
                  },
                  {
                    Icon: getIcon("duplicate"),
                    label: t("common:duplicate"),
                    mustConfirm: true,
                    onClick: () => api.duplicateProject({ projectId: id as string }),
                  },
                  {
                    Icon: getIcon("merge"),
                    label: `${t("common:merge")} ${t("common:project", { count: 2 })}`,
                    onClick: () => setMergeDialogOpen(true),
                  },
                  {
                    Icon: getIcon("offer"),
                    label: t("projects:detail.show_offer"),
                    onClick: () => navigate(linkToOffer(project.offerId!)),
                    isHidden: !project.offerId,
                  },
                  {
                    Icon: getIcon("download"),
                    label: t("common:download_pdf", "Download PDF"),
                    onClick: () => handleDownloadPDF(),
                  },
                  {
                    Icon: getIcon("invoice"),
                    label: t("projects:mark_as_invoiced"),
                    onClick: () => setShowMarkInvoicedDialog(true),
                  },
                ]}
              />
            </>
          </div>
        )}
      >
        {!pathname.match(/\/finance/) && <PageTabs tabs={pageTabs} preserveSearchParams />}
        <Suspense fallback={<PageSpinner />}>
          <Outlet
            context={{
              entity: projectQ.data?.data,
              kpiData: kpiData,
              refreshCache: () =>
                queryClient.invalidateQueries({
                  queryKey: [CACHE_PROJECTS, "detail", id],
                }),
            }}
          />
        </Suspense>
      </PageLayout>
      <Dialog
        open={showMarkInvoicedDialog}
        onOpenChange={() => setShowMarkInvoicedDialog(false)}
        render={({ onClose }) => (
          <MarkInvoicedDialog onClose={onClose} onSubmit={handleMarkAsInvoiced} />
        )}
      />
      <FullScreenFilePreview
        open={!!previewFile}
        file={previewFile}
        onClose={() => setPreviewFile(undefined)}
      />
    </>
  );
}
