import { ReactElement, useEffect, useMemo, useState } from "react";
import DateInput from "~/lib/ui/calendar/date-input";
import { getWeekNumber } from "~/lib/utils/date/date-utils";
import { AutoCompleteInput } from "~/lib/ui/autocomplete";
import { useSimpleSearch } from "~/lib/ui/autocomplete/use-simple-search";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { ControlPanelApiProjectStatusResponse } from "@apacta/sdk";
import { twMerge } from "tailwind-merge";
import * as Popover from "@radix-ui/react-popover";
import { RichTextEditor } from "~/lib/ui/rich-text-editor";
import { Button, Dialog, Icon } from "~/lib/ui";
import StackedBarChart, { BarChartData } from "~/lib/ui/charts/stacked-bar-chart";
import { formatCurrency } from "~/lib/utils/number";
import colors from "tailwindcss/colors";
import { CreateTaskDialog } from "~/pages/planning/_cmp/crud/create-task-dialog";
import { SkeletonLoader } from "~/lib/ui/skeleton";

function PlaygroundGridSection({
  title,
  fullWidth = false,
  children,
}: {
  title: string;
  fullWidth?: boolean;
  children?: ReactElement | Array<ReactElement>;
}) {
  return (
    <section className="mx-auto mt-8 max-w-7xl px-4 sm:px-6 md:px-8">
      <h2>{title}</h2>
      <div
        className={twMerge(
          "overflow-hidden rounded-lg shadow sm:grid sm:gap-1  sm:divide-y-0",
          fullWidth ? "sm:grid-cols-1" : "sm:grid-cols-2"
        )}
      >
        {children}
      </div>
    </section>
  );
}

function PlaygroundGridCard({
  title,
  children,
}: {
  title: string;
  children?: ReactElement | Array<ReactElement>;
}) {
  return (
    <div className="group bg-white p-6">
      <div className="mb-8">
        <h3>{title}</h3>
      </div>
      <div>{children}</div>
    </div>
  );
}

type PlaygroundItem = {
  value: string;
  label: string;
  other: { label: string };
  nextValue: Array<number>;
};

export default function PlaygroundPage() {
  const items: Array<PlaygroundItem> = [
    {
      value: "whatever",
      label: "Aldershvilevej 21, 5800 Nyborg",
      other: { label: "Aldershvilevej 21" },
      nextValue: [1, 2, 3],
    },
    { value: "whatever", label: "allala", other: { label: "test" }, nextValue: [1, 2, 3] },
    { value: "whatever", label: "gs34f", other: { label: "test" }, nextValue: [1, 2, 3] },
    { value: "whatever", label: "sdhjkjl3", other: { label: "test" }, nextValue: [1, 2, 3] },
    { value: "whatever", label: "tweuy890", other: { label: "test" }, nextValue: [1, 2, 3] },
    { value: "whatever", label: "hj8iogsd", other: { label: "test" }, nextValue: [1, 2, 3] },
    { value: "whatever", label: "gsd89", other: { label: "test" }, nextValue: [1, 2, 3] },
    { value: "whatever", label: "fdsfds", other: { label: "test" }, nextValue: [1, 2, 3] },
    { value: "whatever", label: "gs5643634d89", other: { label: "test" }, nextValue: [1, 2, 3] },
    { value: "whatever", label: "gfdgsd89", other: { label: "test" }, nextValue: [1, 2, 3] },
  ];

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const filteredItems: Array<PlaygroundItem> = useMemo(() => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 1000); //simulate load time
    return items.filter((item) => {
      if (item.label.toLowerCase().includes(searchQuery.toLowerCase().trim())) return true;
      return item.value.toLowerCase().includes(searchQuery.toLowerCase().trim());
    });
  }, [searchQuery]);

  const [selectedItems, setSelectedItems] = useState<Array<PlaygroundItem>>([]);

  const handleSelect = (item: PlaygroundItem): void => {
    console.log("handle select");
    setSelectedItems((prevState) => {
      const index: number = prevState.findIndex((v) => v === item);
      if (index < 0) {
        return [...prevState, item];
      } else {
        const newArr = [...prevState];
        newArr.splice(index, 1);
        return newArr;
      }
    });
  };

  const handleChange = (v: string) => {
    setSearchQuery(v);
  };

  const displayValue = (): string => {
    return selectedItems.length ? selectedItems[0].other.label : searchQuery;
  };

  const api = useAPI();

  const statusQ = useQuery({
    queryKey: ["projectStatus"],
    queryFn: () => api.iListProjectStatuses({}),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    placeholderData: keepPreviousData,
  });

  const chartData: Array<BarChartData> = [
    {
      name: "products",
      color: colors["blue"][500],
      position: 0,
      data: [
        {
          x: "Week 1",
          y: -11000,
        },
        {
          x: "Week 2",
          y: -17000,
        },
        {
          x: "Week 3",
          y: -15000,
        },
        {
          x: "Week 4",
          y: -15000,
        },
      ],
    },
    {
      name: "hours",
      color: colors["orange"][500],
      position: 1,
      data: [
        {
          x: "Week 1",
          y: -13000,
        },
        {
          x: "Week 2",
          y: -23000,
        },
        {
          x: "Week 3",
          y: -20000,
        },
        {
          x: "Week 4",
          y: -8000,
        },
      ],
    },
    {
      name: "contribution",
      color: colors["green"][500],
      position: 2,
      data: [
        {
          x: "Week 1",
          y: 44000,
        },
        {
          x: "Week 2",
          y: 55000,
        },
        {
          x: "Week 3",
          y: 41000,
        },
        {
          x: "Week 4",
          y: 67000,
        },
      ],
    },
  ];

  return (
    <div className="pb-20 pt-6">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <h1 className="text-4xl font-bold text-zinc-800">Playground</h1>
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <h2 className="text-2xl font-bold text-zinc-800">Chart</h2>
        <div className="bg-white p-6 shadow sm:rounded-lg">
          <StackedBarChart
            className="h-[30rem]"
            data={chartData}
            yAxisFormatter={(value) => formatCurrency(value)}
            totalFormatter={(values) => {
              const total = values.dataSeries.reduce((acc, curr) => {
                return acc + Object.values(curr)[0];
              }, 0);
              return formatCurrency(total);
            }}
          />
        </div>
      </div>

      <PlaygroundGridSection title="Create Task Modal">
        <PlaygroundGridCard title="Open dialog">
          <SkeletonLoader template="list" className="w-full" />
          <Dialog
            trigger={<Button variant="tertiary">Open</Button>}
            className="md:max-w-4xl"
            render={({ onClose }) => (
              <CreateTaskDialog onClose={() => onClose()} onSubmit={() => onClose()} />
            )}
          ></Dialog>
        </PlaygroundGridCard>
      </PlaygroundGridSection>

      <PlaygroundGridSection title="Combobox (Autocomplete)">
        <PlaygroundGridCard title="With button">
          <DateInput label="Date" name="date" onChange={(d) => console.log(getWeekNumber(d))} />
        </PlaygroundGridCard>
        <AutoCompleteCard />
      </PlaygroundGridSection>
      <PlaygroundGridSection title="Status">
        <PlaygroundGridCard title="Status label">
          {statusQ.data?.data[0] && (
            <ProjectStatusLabel
              values={statusQ.data?.data || []}
              defaultValue={statusQ.data?.data[0]}
              readonly={false}
            />
          )}
        </PlaygroundGridCard>
      </PlaygroundGridSection>
      <PlaygroundGridSection title="WYSIWYG" fullWidth={true}>
        <PlaygroundGridCard title="Editor">
          <RichTextEditor />
        </PlaygroundGridCard>
      </PlaygroundGridSection>
    </div>
  );
}

const ProjectStatusLabel = ({
  readonly = true,
  values,
  defaultValue,
}: {
  readonly?: boolean;
  values?: Array<ControlPanelApiProjectStatusResponse>;
  defaultValue?: ControlPanelApiProjectStatusResponse;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<ControlPanelApiProjectStatusResponse | undefined>(
    defaultValue
  );

  const openStyle = "bg-green-50 text-green-700";
  const readyStyle = "bg-yellow-50 text-yellow-700";
  const closedStyle = "bg-red-50 text-red-700";
  const unknownStyle = "bg-gray-50 text-gray-700";

  const getStyle = (item?: ControlPanelApiProjectStatusResponse): string => {
    if (item?.projectStatusType.identifier === "open") {
      return openStyle;
    } else if (item?.projectStatusType.identifier === "closed") {
      return closedStyle;
    } else if (item?.projectStatusType.identifier?.includes("ready")) {
      return readyStyle;
    } else {
      return unknownStyle;
    }
  };

  const [style, setStyle] = useState<string>(getStyle(defaultValue));

  useEffect(() => {
    if (!!selected) {
      setStyle(getStyle(selected));
    }
  }, [selected]);

  const handleSelect = (item: ControlPanelApiProjectStatusResponse): void => {
    setSelected(item);
    setOpen(false);
  };

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger disabled={readonly} className={twMerge("-ml-px", !readonly && "hover:z-10")}>
        <span
          className={twMerge(
            "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium",
            style
          )}
        >
          {selected?.name || "Unknown"}
          {!readonly && (
            <span>
              <Icon name="chevronDown" className="ml-1 h-4 w-4" aria-hidden="true" />
            </span>
          )}
        </span>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content align="start">
          <div className="min-w-[10rem] rounded-lg bg-white p-2 shadow">
            <ul>
              {values?.map((v, i) => (
                <li
                  key={`status-${i}`}
                  className="flex cursor-pointer gap-2 rounded-lg p-2 hover:bg-tertiary hover:text-white"
                  onClick={() => handleSelect(v)}
                >
                  <div
                    className={twMerge(
                      "flex h-6 w-6 items-center justify-center rounded-full bg-opacity-100 p-1.5",
                      getStyle(v)
                    )}
                  >
                    <svg fill="currentColor" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="11.5" cy="11.5" r="11.5" />
                    </svg>
                  </div>
                  <span>{v.name}</span>
                </li>
              ))}
            </ul>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

function AutoCompleteCard() {
  const [q, setQ] = useState<string>("");
  const { filteredItems, loading } = useSimpleSearch({
    query: q,
    items: [
      { name: "Iron Man" },
      { name: "Superman" },
      { name: "Batman" },
      { name: "Spiderman" },
      { name: "Hulk" },
    ],
    filterFn: (item, query) => item.name.toLowerCase().includes(query.toLowerCase()),
  });

  function handleChange(v: string) {
    setQ(v);
  }
  return (
    <>
      <PlaygroundGridCard title="Autocomplete">
        <AutoCompleteInput
          label="Controlled"
          loading={loading}
          items={filteredItems}
          displayFn={(i) => i.name}
          name="carsize"
          onChange={handleChange}
          value={q}
          onSelect={(item) => setQ(item.name)}
        />
        <AutoCompleteInput
          label="Uncontrolled"
          loading={loading}
          items={filteredItems}
          displayFn={(i) => i.name}
          valueFn={(i) => i.name}
          name="superheroes2"
          onChange={handleChange}
          defaultValue={q}
          onSelect={(item) => setQ(item.name)}
        />
      </PlaygroundGridCard>
    </>
  );
}

/*
      <Combobox2
        label="Simple"
        isLoading={loading}
        items={items}
        // renderSelectItem={(item, selected) => (
        //   <div className="flex flex-row justify-between">
        //     {item.name} {selected && <CheckIcon className="h-5 w-5" />}
        //   </div>
        // )}
        //initialSelection={[{ name: "Test 1" }]} // or item
        initialSelection="Test 1" // value
        compareFn={(a, b) => a.name === b?.name}
        //filterFn={(q, item) => item.name.toLowerCase().includes(q.toLowerCase())}
        inputValueFn={(item) => item.name}
        onInputChange={(val) => setQuery(val)}
        onSelect={(item) => console.log("selected, yo", item)}
        //onAddEntity={(item) => console.log("create sub item", item)}
        //renderAdd={(q) => <Button>{`Add stuff "${q}"`}</Button>}
      />

*/
