import { useState } from "react";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { LabeledTextarea } from "~/lib/ui/form-elements/labeled-textarea";
import { WallPostWallCommentsInner } from "@apacta/sdk";
import { useTranslation } from "react-i18next";

export function EditCommentDialog({
  comment,
  onClose,
  onEditComment,
}: {
  comment: WallPostWallCommentsInner;
  onClose: () => void;
  onEditComment: (args: { message: string; postId: string; commentId: string }) => Promise<unknown>;
}) {
  const [message, setMessage] = useState<string>(comment.message ?? "");
  const nothingToSubmit = message === "" || message === comment.message;

  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <h3 className="mb-2">{t("projects:feed.edit_a_comment")}</h3>
      <LabeledTextarea
        required
        onChange={(e) => setMessage(e.currentTarget.value)}
        defaultValue={message}
        className="mb-4 mt-2 block min-h-20 w-full rounded-md border-gray-300 shadow-sm focus:border-hover focus:outline-none focus:ring-transparent sm:text-sm"
      />

      <DialogFooter
        primary={{
          disabled: nothingToSubmit,
          label: t("common:save_changes"),
          onClick: async () => {
            await onEditComment({
              message: message,
              postId: comment.wallPostId as string,
              commentId: comment.id,
            });
            onClose();
          },
        }}
        onClose={onClose}
      />
    </div>
  );
}
