import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useTranslation } from "react-i18next";

export default function SaveUnavailableDialog({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  return (
    <>
      <DialogHeader
        title={t("offers:error.limit_exceeded.title")}
        Icon={ExclamationTriangleIcon}
        variant="alert"
      />
      <div className="mb-12">
        <div className="mt-3 sm:mt-5">
          <div className="mt-2 whitespace-pre-line text-base text-shade-900">
            {t("offers:error.limit_exceeded")}
          </div>
        </div>
      </div>
      <DialogFooter
        hideCancel={true}
        onClose={() => onClose()}
        primary={{
          label: t("common:close"),
          variant: "secondary",
          onClick: () => onClose(),
        }}
      />
    </>
  );
}
