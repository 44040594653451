import { ComponentProps, ReactNode } from "react";
import ContentLoader from "react-content-loader";
import { twMerge } from "tailwind-merge";

// Lots of examples here: https://skeletonreact.com/
export const templates = {
  box: { options: {}, render: () => <rect x={0} y={0} width="100%" height="100%"></rect> },
  list: {
    options: {
      speed: 2,
      width: 207,
      height: 135,
      viewBox: "0 0 207 135",
    },
    render: (opts) => (
      <>
        <rect x="10" y="10" rx="4" ry="4" width="20" height="15" />
        <rect x="47" y="10" rx="4" ry="4" width="150" height="15" />
        <rect x="10" y="35" rx="4" ry="4" width="20" height="15" />
        <rect x="47" y="35" rx="4" ry="4" width="150" height="15" />
        <rect x="10" y="60" rx="4" ry="4" width="20" height="15" />
        <rect x="47" y="60" rx="4" ry="4" width="150" height="15" />
        <rect x="10" y="85" rx="4" ry="4" width="20" height="15" />
        <rect x="47" y="85" rx="4" ry="4" width="150" height="15" />
        <rect x="10" y="110" rx="4" ry="4" width="20" height="15" />
        <rect x="47" y="110" rx="4" ry="4" width="150" height="15" />
      </>
    ),
  },
  carouselThumbnails: {
    options: {
      height: 64,
      width: 720,
      speed: 2,
      viewBox: "0 0 720 64",
    },
    render: (opts) => (
      <>
        <rect x={0} y={0} width={72} height={64} />
        <rect x={80} y={0} width={72} height={64} />
        <rect x={160} y={0} width={72} height={64} />
        <rect x={240} y={0} width={72} height={64} />
        <rect x={320} y={0} width={72} height={64} />
        <rect x={400} y={0} width={72} height={64} />
        <rect x={480} y={0} width={72} height={64} />
        <rect x={560} y={0} width={72} height={64} />
        <rect x={640} y={0} width={72} height={64} />
      </>
    ),
  },
  bulletList: {
    options: {
      speed: 2,
      width: 400,
      height: 150,
      viewBox: "0 0 400 150",
    },
    render: (opts) => (
      <>
        <circle cx="10" cy="20" r="8" />
        <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="50" r="8" />
        <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="80" r="8" />
        <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="110" r="8" />
        <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
      </>
    ),
  },
  code: {
    options: {
      speed: 2,
      width: 340,
      height: 84,
      viewBox: "0 0 340 84",
    },
    render: (opts) => (
      <>
        <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
        <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
        <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
        <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
        <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
        <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
        <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
        <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
      </>
    ),
  },
  facebook: {
    options: {
      speed: 2,
      width: 476,
      height: 124,
      viewBox: "0 0 476 124",
    },
    render: (opts) => (
      <>
        <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
        <circle cx="20" cy="20" r="20" />
      </>
    ),
  },
  // A single card-row with 4 cards
  row: {
    options: {
      speed: 2,
      viewBox: "0 0 2000 200",
    },
    render: () => (
      <>
        <rect x="0" y="0" rx="3" ry="3" width="24%" height="100%" />
        <rect x="25%" y="0" rx="3" ry="3" width="24%" height="100%" />
        <rect x="50%" y="0" rx="3" ry="3" width="24%" height="100%" />
        <rect x="75%" y="0" rx="3" ry="3" width="24%" height="100%" />
      </>
    ),
  },
  document: {
    options: {
      speed: 3,
      viewBox: "0 0 600 800",
    },
    render: () => (
      <>
        <rect x="0" y="10" rx="6" ry="6" width="40%" height="14" />

        <rect x="0" y="60" rx="6" ry="6" width="60%" height="14" />
        <rect x="0" y="90" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="120" rx="6" ry="6" width="100%" height="14" />

        <rect x="0" y="200" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="230" rx="6" ry="6" width="80%" height="14" />
        <rect x="0" y="260" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="290" rx="6" ry="6" width="80%" height="14" />
        <rect x="0" y="320" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="350" rx="6" ry="6" width="80%" height="14" />
        <rect x="0" y="380" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="410" rx="6" ry="6" width="80%" height="14" />

        <rect x="0" y="500" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="530" rx="6" ry="6" width="80%" height="14" />
        <rect x="0" y="560" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="590" rx="6" ry="6" width="80%" height="14" />
        <rect x="0" y="620" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="650" rx="6" ry="6" width="80%" height="14" />
        <rect x="0" y="680" rx="6" ry="6" width="100%" height="14" />
        <rect x="0" y="710" rx="6" ry="6" width="80%" height="14" />
      </>
    ),
  },
} satisfies Record<
  string,
  { options: ContentLoaderOptions; render: (opts?: Record<string, unknown>) => ReactNode }
>;

type ContentLoaderOptions = {
  width?: ComponentProps<typeof ContentLoader>["width"];
  height?: ComponentProps<typeof ContentLoader>["height"];
  viewBox?: ComponentProps<typeof ContentLoader>["viewBox"];
  backgroundColor?: ComponentProps<typeof ContentLoader>["backgroundColor"];
  foregroundColor?: ComponentProps<typeof ContentLoader>["foregroundColor"];
  speed?: ComponentProps<typeof ContentLoader>["speed"];
  repeat?: number;
};

type BaseSkeletonLoaderProps = {
  className?: string;
  options?: ContentLoaderOptions;
};

interface SkeletonTemplateProps extends BaseSkeletonLoaderProps {
  template: keyof typeof templates;
  children?: never;
}

interface SkeletonChildrenProps extends BaseSkeletonLoaderProps {
  template?: never;
  children: ReactNode;
}

type SkeletonLoaderProps = SkeletonTemplateProps | SkeletonChildrenProps;

/**
 * Got sick of forgetting how to use ContentLoader every time.
 *
 * This has predefined shapes we can use, or if you pass children it will override.
 *
 */
export function SkeletonLoader(props: SkeletonLoaderProps) {
  let templateOptions: Record<string, unknown> = {
    ...props.options,
  };
  if (props.template) {
    templateOptions = {
      ...templateOptions,
      ...templates[props.template].options,
    };
  }

  return (
    <ContentLoader
      className={twMerge(defaultClasses, props.className)}
      {...defaultOptions}
      {...templateOptions}
      {...props.options}
    >
      {props.template && templates[props.template].render(templateOptions)}
      {props.children}
    </ContentLoader>
  );
}

const defaultClasses = "";

const defaultOptions: ContentLoaderOptions = {
  backgroundColor: "#f1f5f9",
  foregroundColor: "#94a3b8",
};
