import { DetailsTab } from "~/pages/planning/_cmp/crud/tabs/details-tab";
import { ProductsTab } from "~/pages/planning/_cmp/crud/tabs/products-tab";
import ViewTabs from "~/lib/ui/tabs/view-tabs";
import { TaskFormState } from "~/pages/planning/_cmp/types";
import { useTranslation } from "react-i18next";
import { FormsTab } from "~/pages/planning/_cmp/crud/tabs/forms-tab";
import { Suspense } from "react";

export function PlanningTabs({
  formState,
  selectedEmployee,
  taskId,
  isEditPanel = false,
  repeatInfo,
}: {
  formState: TaskFormState;
  selectedEmployee?: string | null;
  taskId?: string;
  isEditPanel?: boolean;
  repeatInfo?: { isInfiniteRepeat?: boolean; endTime?: Date };
}) {
  const { t } = useTranslation();
  return (
    <ViewTabs
      tabSize="small"
      tabs={[
        {
          id: "details-tab",
          label: t("planning:tabs.details"),
        },
        {
          id: "products-tab",
          label: t("planning:tabs.products"),
        },
        {
          id: "forms-tab",
          label: t("planning:tabs.forms"),
        },
      ]}
    >
      <div id="details-tab" className="flex flex-col pb-8 text-sm">
        <DetailsTab
          formState={formState}
          repeatInfo={repeatInfo}
          selectedEmployee={selectedEmployee}
        />
      </div>
      <div id="products-tab" className="flex flex-col pb-8 text-sm">
        <ProductsTab formState={formState} taskId={taskId} isEditPanel={isEditPanel} />
      </div>
      <div id="forms-tab" className="flex flex-col pb-8 text-sm">
        <Suspense>
          <FormsTab formState={formState} />
        </Suspense>
      </div>
    </ViewTabs>
  );
}
