import { useTranslation } from "react-i18next";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { SelectionCombobox } from "~/lib/ui/selection-combobox";

export function TimeEntryRuleGroupsInput({
  onChange,
  initialEntryId,
  hideLabel = false,
}: {
  onChange: (groupId: string | null) => void;
  initialEntryId?: string;
  hideLabel?: boolean;
}) {
  const api = useAPI();
  const { t } = useTranslation();

  const { data, isLoading } = useSuspenseQuery({
    queryKey: ["time_entry_rule_groups"],
    queryFn: () => api.getTimeEntryRuleGroups(),
  });

  const groups = data?.data ?? [];

  return (
    <SelectionCombobox
      required
      defaultValue={initialEntryId}
      onSelect={(v) => onChange?.(v)}
      label={hideLabel ? "" : t("common:time_entry_rule_group")}
      data={groups}
      valueFn={(e) => e.id ?? ""}
      labelFn={(e) => e.name ?? ""}
      loading={isLoading}
    />
  );
}
