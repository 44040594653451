import { StrictMode, Suspense } from "react";

import "./styles/globals.css"; // Tailwind + custom CSS
import "./prototypes"; // Prototypes
import { NavigationRoot } from "./lib/navigation/navigation-root";

import { QueryClientProvider } from "./query-client";
import { ToastProvider } from "./lib/toast/context";
import { initTranslations } from "./lib/i18n/i18n";
import { IntercomProvider } from "react-use-intercom";
import { INTERCOM_APP_ID } from "./lib/auth/config";
import { SessionContextProvider } from "./lib/auth/context";
import { ErrorBoundary } from "react-error-boundary";
import { SessionError } from "./lib/auth/session-error";
import { AppLoader } from "./lib/auth/app-loader";
import { createRoot } from "react-dom/client";

// This looks weird, but is i18next's recommended way of initializing translations
initTranslations();

const rootEl = document.getElementById("root");
if (!rootEl) {
  throw new Error("No root element found, cannot render app");
}

const app = createRoot(rootEl);

function App() {
  return (
    <StrictMode>
      <Suspense fallback={<AppLoader />}>
        <ToastProvider>
          <QueryClientProvider>
            <ErrorBoundary fallback={<SessionError />}>
              <SessionContextProvider>
                <IntercomProvider appId={INTERCOM_APP_ID}>
                  <NavigationRoot />
                </IntercomProvider>
              </SessionContextProvider>
            </ErrorBoundary>
          </QueryClientProvider>
        </ToastProvider>
      </Suspense>
    </StrictMode>
  );
}

app.render(<App />);
