import { CreateProductRequest, PriceMargin } from "@apacta/sdk";
import { FolderPlusIcon } from "@heroicons/react/24/outline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import TextInput from "~/lib/ui/form-elements/text-input";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { FormEvent, useRef } from "react";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { linkToProductNew } from "~/lib/utils";
import { useNavigate } from "react-router";
import { CACHE_PRODUCTS } from "~/pages/products";
import { NumberFormatInput } from "~/lib/ui/form-elements/number-format-input";
import { useMe } from "~/lib/auth/use-me";
import Switch from "~/lib/ui/switch";
import { formatDecimals } from "~/lib/utils/number";

export function CreateProductDialog({
  onProductCreated,
  initialName,
  skipNavigate,
}: {
  onProductCreated: (productId?: string) => void;
  initialName?: string;
  skipNavigate?: boolean;
}) {
  const { t } = useTranslation();
  const api = useAPI();
  const me = useMe();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const sellingPriceRef = useRef<HTMLInputElement | null>(null);
  const { register, isValid, getValue, setValues } = useFormState({
    schema: {
      name: z.string().min(1),
      buying_price: z.number(),
      selling_price: z.number().optional(),
      price_type: z.enum(["manual", "cost_based"]),
    },
    initialValues: {
      name: initialName ?? "",
      buying_price: 0,
      selling_price: 0,
      price_type: "cost_based",
    },
  });

  const createMutation = useMutation({
    mutationFn: (args: CreateProductRequest) => api.createProduct({ createProductRequest: args }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_PRODUCTS],
      });
      if (data.data.id) {
        onProductCreated(data.data?.id || undefined);
        if (!skipNavigate) {
          navigate(linkToProductNew(data.data.id));
        }
      }
    },
  });

  function calculateByPriceMargin(buyingPrice: number): number {
    const priceMargins = me.priceMargins;
    const priceMargin = priceMargins.find(
      (m: PriceMargin) => m.amountFrom <= buyingPrice && m.amountTo >= buyingPrice
    );

    if (
      !priceMargin ||
      !priceMargin.percentageRatio ||
      Math.abs(100 - priceMargin.percentageRatio) < 0.01
    ) {
      return buyingPrice; // No change if no valid margin is found
    }

    const margin = 1 / (1 - priceMargin.percentageRatio / 100);

    return Number((margin * buyingPrice).toFixed(2));
  }

  function updateBuyingAndSellingPrice(buyingPrice: number | FormEvent<HTMLInputElement>) {
    if (typeof buyingPrice !== "number") {
      buyingPrice = 0;
    }
    // Only update selling price if the price type is cost based
    if (getValue("price_type") === "cost_based") {
      const sellingPrice = calculateByPriceMargin(buyingPrice);
      setValues({
        buying_price: Number(buyingPrice) ?? 0,
        selling_price: Number(sellingPrice),
      });
      if (sellingPriceRef.current) {
        sellingPriceRef.current.value = formatDecimals(sellingPrice);
      }
    } else {
      setValues({
        buying_price: Number(buyingPrice) ?? 0,
      });
    }
  }

  function handleSubmit() {
    createMutation.mutate({
      name: getValue("name"),
      buyingPrice: getValue("buying_price"),
      sellingPrice: getValue("selling_price"),
      priceType: getValue("price_type"),
    });
  }

  return (
    <>
      <DialogHeader
        title={t("common:create", {
          replace: { entity: t("common:product", { count: 1 }).toLocaleLowerCase() },
        })}
        Icon={FolderPlusIcon}
      />
      <div className="mb-8 gap-6">
        <TextInput {...register("name")} label={t("common:name")} />
        <div className="bg-whitemd:flex-row fle x-col mt-3 flex gap-6">
          <div className="flex flex-1 flex-col gap-1 md:basis-4/12">
            <NumberFormatInput
              label={t("products:cost_price")}
              className="text-right text-sm"
              name="buying_price"
              defaultValue={getValue("buying_price")}
              onChange={(v) => updateBuyingAndSellingPrice(v)}
            />
          </div>
          <div className="flex flex-1 flex-col gap-1 md:basis-8/12">
            <NumberFormatInput
              ref={sellingPriceRef}
              label={t("products:selling_price")}
              className="text-right text-sm"
              name="selling_price"
              defaultValue={getValue("selling_price") ?? ""}
              disabled={getValue("price_type") !== "manual"}
              onChange={(v) => setValues({ selling_price: Number(v) ?? 0 })}
            />
          </div>
        </div>
        <div className="mt-5 flex flex-col sm:flex-row">
          <Switch
            label={t("products:modal.manual_selling_price")}
            defaultChecked={getValue("price_type") === "manual"}
            onCheckedChange={(v) => {
              setValues({ price_type: v ? "manual" : "cost_based" });
            }}
            className="justify-between sm:justify-start"
          />
        </div>
      </div>
      <DialogFooter
        primary={{
          label: t("common:create", {
            replace: { entity: t("common:product", { count: 1 }).toLocaleLowerCase() },
          }),
          onClick: handleSubmit,
          disabled: !isValid || createMutation.isPending,
          loading: createMutation.isPending,
        }}
        onClose={onProductCreated}
      />
    </>
  );
}
