import { useQuery } from "@tanstack/react-query";
import { ReactNode, useState } from "react";
import { useAPI } from "~/lib/api";
import { Button, Dialog } from "~/lib/ui";
import { PageLayout } from "~/lib/ui/page-layout";
import { SelectionCombobox } from "~/lib/ui/selection-combobox/selection-combobox";

import ViewTabs from "~/lib/ui/tabs/view-tabs";
import { CreateEmployeeDialog } from "../employees/_cmp/create-employee-dialog";
import { EmployeeSelection } from "~/lib/ui/selection-combobox/employee-combobox";

export default function Selection() {
  return (
    <PageLayout title="Selection">
      <ViewTabs
        tabs={[
          {
            id: "single-controlled",
            label: "Single Controlled",
          },
          {
            id: "single-uncontrolled",
            label: "Single Uncontrolled",
          },
          {
            id: "multi-controlled",
            label: "Multi Controlled",
          },
          {
            id: "multi-uncontrolled",
            label: "Multi Uncontrolled",
          },
        ]}
      >
        <SingleControlled />
        <SingleUncontrolled />
        <MultiControlled />
        <MultiUncontrolled />
      </ViewTabs>
      <ViewTabs
        tabs={[
          {
            id: "entity-basic",
            label: "Entity",
          },
          { id: "loading", label: "Loading" },
          { id: "manual-filtering", label: "Manual Filtering" },
          { id: "readonly", label: "Readonly" },
        ]}
      >
        <EntityBasic />
        <Loading />
        <ManualFiltering />
        <Readonly />
      </ViewTabs>
    </PageLayout>
  );
}

// Component for each of the tab
function SingleControlled() {
  const [value, setValue] = useState<string | null>("react");
  return (
    <Showcase label="Normal">
      <SelectionCombobox
        controlled
        data={[
          {
            name: "React",
            value: "react",
          },
          {
            name: "Vue",
            value: "vue",
          },
        ]}
        labelFn={(d) => d.name}
        valueFn={(d) => d.value}
        value={value ?? undefined}
        onSelect={(val, item) => {
          setValue(val);
          console.log("you selected " + item.name);
        }}
      />
    </Showcase>
  );
}
function SingleUncontrolled() {
  return (
    <Showcase label="Normal">
      <SelectionCombobox
        data={[
          {
            name: "React",
            value: "react",
          },
          {
            name: "Vue",
            value: "vue",
          },
        ]}
        labelFn={(d) => d.name}
        valueFn={(d) => d.value}
        defaultValue="vue"
        onSelect={(val, item) => {
          console.log("you selected " + item.name);
        }}
      />
    </Showcase>
  );
}
function MultiControlled() {
  // TODO. Finish this
  const [values, setValues] = useState<Array<string>>(["1"]);
  const data = [
    // List of power tools
    {
      name: "Drill",
      id: "1",
    },
    {
      name: "Saw",
      id: "2",
    },
    {
      name: "Hammer",
      id: "3",
    },
    {
      name: "Screwdriver",
      id: "4",
    },
    {
      name: "Nailgun",
      id: "5",
    },
  ];
  return (
    <SelectionCombobox
      multiple
      controlled
      label="Power tools"
      data={data}
      labelFn={(d) => d.name}
      valueFn={(d) => d.id}
      value={values}
      onSelect={(vals, items) => {
        setValues(vals);
        console.log("multiselect", vals, items);
      }}
    />
  );
}
function MultiUncontrolled() {
  return (
    <Showcase label="Normal w. smurfs ">
      <SelectionCombobox
        multiple
        data={[
          { name: "Papa Smurf", id: "1" },
          { name: "Smurfette", id: "2" },
          { name: "Brainy Smurf", id: "3" },
          { name: "Hefty Smurf", id: "4" },
          { name: "Clumsy Smurf", id: "5" },
          { name: "Grouchy Smurf", id: "6" },
          { name: "Vanity Smurf", id: "7" },
          { name: "Jokey Smurf", id: "8" },
          { name: "Greedy Smurf", id: "9" },
          { name: "Handy Smurf", id: "10" },
          { name: "Harmony Smurf", id: "11" },
          { name: "Painter Smurf", id: "12" },
          { name: "Poet Smurf", id: "13" },
          { name: "Farmer Smurf", id: "14" },
          { name: "Reporter Smurf", id: "15" },
          { name: "Dreamy Smurf", id: "16" },
          { name: "Tracker Smurf", id: "17" },
          { name: "Clockwork Smurf", id: "18" },
          { name: "Sloppy Smurf", id: "19" },
          { name: "Sweepy Smurf", id: "20" },
          { name: "Scaredy Smurf", id: "21" },
          { name: "Sassette Smurfling", id: "22" },
          { name: "Nat Smurfling", id: "23" },
          { name: "Snappy Smurfling", id: "24" },
          { name: "Slouchy Smurfling", id: "25" },
          { name: "Grandpa Smurf", id: "26" },
          { name: "Nanny Smurf", id: "27" },
          { name: "Sweety Smurf", id: "28" },
          { name: "Wild Smurf", id: "29" },
          { name: "Baby Smurf", id: "30" },
          { name: "Alchemist Smurf", id: "31" },
        ]}
        defaultValue={["1", "2", "3"]}
        valueFn={(d) => d.id}
        labelFn={(d) => d.name}
        onSelect={(vals, items) => {
          console.log("multiselect", vals, items);
        }}
        label="Smurf selector"
      />
    </Showcase>
  );
}

function Showcase({ label, children }: { label: string; children: ReactNode }) {
  return (
    <div className="max-w-xl p-8">
      <h2 className="mb-4 text-lg font-semibold">{label}</h2>
      {children}
    </div>
  );
}

function EntityBasic() {
  const [value, setValue] = useState<string | null>("17c332a1-1d78-4ce1-885e-2f77d24c1a02");
  const [multi, setMulti] = useState<Array<string>>(["17c332a1-1d78-4ce1-885e-2f77d24c1a02"]);
  return (
    <>
      <EmployeeSelection
        label="Employee Uncontrolled"
        defaultValue="17c332a1-1d78-4ce1-885e-2f77d24c1a02"
        onSelect={(val, item) => console.log("entity picked", val, item)}
      />
      <EmployeeSelection
        controlled
        label="Employee Controlled"
        value={value ?? undefined}
        onSelect={(val, item) => setValue(val)}
      />
      <EmployeeSelection
        multiple
        label="Employees Uncontrolled"
        defaultValue={["17c332a1-1d78-4ce1-885e-2f77d24c1a02"]}
        onSelect={(val, item) => console.log("entity picked", val, item)}
      />
      <EmployeeSelection
        multiple
        controlled
        label="Employees Controlled"
        value={multi}
        onSelect={(val, item) => setMulti(val)}
      />
    </>
  );
}

function Loading() {
  const [loading, setLoading] = useState(true);

  return (
    <div className="max-w-xl">
      <Button variant="secondary" onClick={() => setLoading((prev) => !prev)} className="mb-4">
        Toggle
      </Button>
      <div className="flex flex-col gap-4">
        <div>
          <SelectionCombobox
            data={["Test"]}
            valueFn={(d) => d}
            onSelect={() => console.log("yay")}
            labelFn={(d) => d}
            loading={loading}
          />
        </div>
        <div>
          <SelectionCombobox
            multiple
            data={["Test"]}
            valueFn={(d) => d}
            onSelect={() => console.log("yay")}
            labelFn={(d) => d}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

function Readonly() {
  return (
    <div className="max-w-xl">
      <div className="flex flex-col gap-4">
        <div>
          <SelectionCombobox
            label="Best snack in the world"
            data={["Peanuts", "Chips", "Popcorn", "Chocolate"]}
            value="Popcorn"
            valueFn={(d) => d}
            onSelect={() => console.log("yay")}
            labelFn={(d) => d}
            controlled
            readonly
          />
        </div>
        <div>
          <SelectionCombobox
            label="Favourite snacks"
            defaultValue={["Peanuts", "Chips"]}
            data={["Peanuts", "Chips", "Popcorn", "Chocolate"]}
            valueFn={(d) => d}
            onSelect={() => console.log("yay")}
            labelFn={(d) => d}
            multiple
            readonly
          />
        </div>
      </div>
    </div>
  );
}

function ManualFiltering() {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [createQuery, setCreateQuery] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState<string | null>(""); // userId
  const [searchQuery, setSearchQuery] = useState("");

  const api = useAPI();
  const uq = useQuery({
    queryKey: ["users", searchQuery],
    queryFn: () =>
      api.getUsers({
        q: searchQuery,
      }),
  });
  const users = uq.data?.data || [];

  function handleCreated(userId: string) {
    uq.refetch();
    setSelectedEmployee(userId);
  }

  return (
    <>
      <SelectionCombobox
        controlled
        data={users}
        valueFn={(user) => user.id}
        labelFn={(user) => user.fullName}
        loading={uq.isFetching}
        value={selectedEmployee ?? undefined}
        onQueryChange={setSearchQuery}
        onSelect={(val) => {
          setSelectedEmployee(val);
        }}
        onCreateNew={(query) => {
          setCreateQuery(query);
          setIsCreateDialogOpen(true);
        }}
      />
      <Dialog
        open={isCreateDialogOpen}
        onOpenChange={setIsCreateDialogOpen}
        render={({ onClose }) => (
          <CreateEmployeeDialog
            onEmployeeCreated={handleCreated}
            onClose={onClose}
            firstName={createQuery}
          />
        )}
      />
    </>
  );
}
