// Purpose: Render a standard confirmation dialog

import { useTranslation } from "react-i18next";
import { DialogFooter } from "../dialog-footer";
import { IconProp } from "~/lib/ui/types";
import { DialogHeader } from "../dialog-header";
import { getIcon } from "~/lib/ui";

export function NotAllowedModal({
  Icon,
  variant,
  title,
  description,
  closeLabel,
  onClose,
}: {
  Icon?: IconProp;
  title?: string;
  description?: string;
  closeLabel?: string;
  onClose: () => void;
  variant?: "success" | "warning" | "alert";
}) {
  const { t } = useTranslation();
  return (
    <>
      <DialogHeader
        title={title ?? t("ui:confirm.title")}
        Icon={Icon ?? getIcon("unknownType")}
        variant={variant}
      />
      <div className="mb-12">
        <div className="mt-3 sm:mt-5">
          <div className="mt-2 whitespace-pre-line text-base text-shade-900">
            {description ?? t("ui:confirm.description")}
          </div>
        </div>
      </div>
      <DialogFooter
        onClose={() => onClose()}
        hideCancel={true}
        primary={{
          label: closeLabel ?? t("common:close"),
          onClick: () => onClose(),
        }}
      />
    </>
  );
}
