import {
  ArrowRightIcon,
  ClipboardDocumentIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useTranslation } from "react-i18next";
import { ControlPanelApiProjectViewOrIndexResponse } from "@apacta/sdk";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { ProjectSelection } from "~/lib/ui/selection-combobox/project-selection";

export default function MergeProjectModal({
  targetProject,
  onClose,
  onSubmit,
}: {
  targetProject: ControlPanelApiProjectViewOrIndexResponse;
  onClose: () => void;
  onSubmit?: () => void;
}) {
  const api = useAPI();
  const { t } = useTranslation();
  const [mergingProject, setMergingProject] =
    useState<ControlPanelApiProjectViewOrIndexResponse | null>(null);
  const [originalProject, setOriginalProject] =
    useState<ControlPanelApiProjectViewOrIndexResponse | null>(targetProject);

  const mergeM = useMutation({
    mutationFn: ({
      from,
      to,
    }: {
      from: ControlPanelApiProjectViewOrIndexResponse;
      to: ControlPanelApiProjectViewOrIndexResponse;
    }) => {
      return api.mergeProjects({
        mergeProjectsRequest: {
          originalProjectId: to.id,
          mergingProjectId: from.id,
        },
      });
    },
  });

  const handleSubmit = async () => {
    if (mergingProject && originalProject) {
      await mergeM.mutateAsync({
        from: mergingProject,
        to: originalProject,
      });
      onSubmit?.();
    }
  };

  return (
    <div>
      <DialogHeader title="Merge Project" Icon={ClipboardDocumentIcon} />
      <div className="flex flex-col gap-8">
        <div className="flex items-end">
          <div className="w-full">
            <ProjectSelection
              label={t("projects:merge.merge_project")}
              placeholder={t("projects:merge.merge_project.placeholder")}
              filterFn={(project) => project.id !== targetProject.id}
              onSelect={(_, project) => setMergingProject(project)}
            />
          </div>
          <div className="mx-4 h-10 w-10 rounded-full bg-tertiary p-2 text-white">
            <ArrowRightIcon className="h-6 w-6" />
          </div>
          <div className="w-full">
            <ProjectSelection
              controlled
              label={t("projects:merge.original_project")}
              placeholder={t("projects:merge.original_project.placeholder")}
              value={targetProject.id || undefined}
              readonly={!!targetProject}
              onSelect={(_, project) => setOriginalProject(project)}
            />
          </div>
        </div>

        <div className="mb-8 flex flex-col gap-2 border bg-gray-100 p-2">
          <div className="flex items-center gap-4">
            <div className="rounded-full bg-red-500 p-1.5 text-white">
              <ExclamationTriangleIcon className="h-6 w-6" />
            </div>
            <div className="text-sm">{t("projects:merge_project_warning")}</div>
          </div>
        </div>
      </div>
      <DialogFooter
        primary={{
          label: t("common:confirm"),
          onClick: () => handleSubmit(),
          disabled: !mergingProject || !originalProject || mergeM.isPending,
        }}
        onClose={() => onClose()}
      />
    </div>
  );
}
