import { getSelectedIds, SelectionCombobox } from "./selection-combobox";
import { useAPI } from "~/lib/api";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Dialog } from "..";
import { SelectionComboboxExtendable, ValueChangeHandler } from "./combobox-types";
import { Contact } from "@apacta/sdk";
import { CreateCustomerDialog } from "~/pages/customers/_cmp/create-customer-dialog";
import { useTranslation } from "react-i18next";

type Entity = Contact; //shortened for readability
export function CustomerSelection<D extends Entity = Entity>(
  props: SelectionComboboxExtendable<D>
) {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [createQuery, setCreateQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);

  const api = useAPI();
  const { t } = useTranslation();
  const vcRef = useRef<ValueChangeHandler>(); // value change ref

  // Array of ids to select. Undefined if searchquery is set
  const idParam = getSelectedIds({
    value: props.value,
    defaultValue: props.defaultValue,
    isSearchDialogOpen,
  });

  const uq = useQuery({
    queryKey: ["contacts", searchQuery, props.value],
    queryFn: () =>
      api.getContacts({
        q: searchQuery,
        ids: idParam,
      }),
  });
  const items = uq.data?.data || [];

  const pendingSelection = useRef<string | undefined>();
  async function handleCreated(userId: string) {
    pendingSelection.current = userId;
    await uq.refetch();
  }

  // Set the value after the data has been loaded
  useEffect(() => {
    if (pendingSelection.current === undefined) return;
    vcRef.current?.(pendingSelection.current);
    pendingSelection.current = undefined;
  }, [items]);

  const fullContactAddress = (c: Contact) => {
    let address = undefined;
    if (c.address) {
      address = c.address;
      if (c.zipCode) {
        address += ", " + c.zipCode;
        if (c.city) {
          address += " " + c.city.name;
        }
      }
    }
    return address;
  };

  return (
    <div>
      <SelectionCombobox<D>
        label={t("common:customer", { count: 1 })}
        {...props}
        data={items as Array<D>}
        valueFn={(entity) => entity.id}
        placeholder={t("projects:select_customer")}
        valueChangeRef={vcRef}
        labelFn={(entity) => entity.name} // searching
        loading={uq.isFetching}
        onQueryChange={setSearchQuery}
        onOpenChange={setIsSearchDialogOpen}
        onCreateNew={(query) => {
          setCreateQuery(query);
          setIsCreateDialogOpen(true);
        }}
        renderItem={(entity) => (
          <div className="flex flex-col gap-2">
            {entity.name}
            {fullContactAddress(entity) && (
              <div className="text-xs opacity-80">{fullContactAddress(entity)}</div>
            )}
          </div>
        )} // displaying
      />
      <Dialog
        open={isCreateDialogOpen}
        onOpenChange={setIsCreateDialogOpen}
        render={({ onClose }) => (
          <CreateCustomerDialog
            onCustomerCreated={handleCreated}
            onOpenChange={onClose}
            defaultCustomerName={createQuery}
          />
        )}
      />
    </div>
  );
}
