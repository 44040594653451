import { Icon } from "~/lib/ui";
import { Input, InputProps } from "~/lib/ui/form-elements";

export function Timepicker(props: InputProps) {
  return (
    <div className="relative">
      <Input {...props} type="time" />
      {props?.disabled ? (
        <div className="pointer-events-none absolute inset-y-0 end-2 top-0 flex items-center">
          <Icon name="time" className="h-5 w-5" />
        </div>
      ) : null}
    </div>
  );
}
