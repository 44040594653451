import * as dateFn from "date-fns";
import { DateRange } from "~/lib/utils/date/date-utils.types";

export type DatePreset =
  | "today"
  | "yesterday"
  | "this_week"
  | "last_week"
  | "last_month"
  | "this_month";

export function getPresetDates(preset: DatePreset, maximumDate?: Date): DateRange {
  switch (preset) {
    case "today":
      return {
        from: dateFn.startOfDay(new Date()),
        to: dateFn.startOfDay(new Date()),
      };
    case "yesterday":
      const yesterday = dateFn.startOfDay(new Date());
      yesterday.setDate(yesterday.getDate() - 1);

      return {
        from: yesterday,
        to: yesterday,
      };
    case "this_week":
      // get dates for start and end of this week
      const startOfWeek = dateFn.startOfWeek(new Date(), { weekStartsOn: 1 });
      let endOfWeek = dateFn.endOfWeek(new Date(), { weekStartsOn: 1 });

      if (maximumDate && endOfWeek > maximumDate) {
        endOfWeek = maximumDate;
      }

      return {
        from: startOfWeek,
        to: endOfWeek,
      };
    case "last_week":
      // get dates for start and end of last week
      const lastWeekDay = dateFn.setDay(new Date(), -7, { weekStartsOn: 1 });
      const startOfLastWeek = dateFn.startOfWeek(lastWeekDay, { weekStartsOn: 1 });
      const endOfLastWeek = dateFn.endOfWeek(lastWeekDay, { weekStartsOn: 1 });
      return {
        from: startOfLastWeek,
        to: endOfLastWeek,
      };
    case "last_month":
      // get dates for start and end of last month
      const lastMonthDay = dateFn.subMonths(new Date(), 1);
      const startOfMonth = dateFn.startOfMonth(lastMonthDay);
      const endOfMonth = dateFn.endOfMonth(lastMonthDay);
      return {
        from: startOfMonth,
        to: endOfMonth,
      };
    case "this_month":
      // get dates for start and end of this month
      const thisMonthDay = new Date();
      const startOfThisMonth = dateFn.startOfMonth(thisMonthDay);
      let endOfThisMonth = dateFn.endOfMonth(thisMonthDay);

      if (maximumDate && endOfThisMonth > maximumDate) {
        endOfThisMonth = maximumDate;
      }

      return {
        from: startOfThisMonth,
        to: endOfThisMonth,
      };
  }
}
