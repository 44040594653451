import { twMerge } from "tailwind-merge";
import { Popover } from "~/lib/ui/popover/popover";
import { DateRangeSelect } from "~/lib/ui/calendar/date-range-select";
import { useLocale } from "~/lib/utils/date";
import { Icon } from "~/lib/ui";
import { useTranslation } from "react-i18next";
import { DateRange } from "~/lib/utils/date/date-utils.types";

export function DateRangePopover({
  dateFrom,
  dateTo,
  resetLabel,
  onReset,
  onChange,
  allowUnset,
}: {
  dateFrom?: Date;
  dateTo?: Date;
  onReset?: () => void;
  resetLabel?: string;
  onChange: (range: DateRange) => void;
  allowUnset?: boolean;
}) {
  const { format } = useLocale();
  const { t } = useTranslation();

  return (
    <Popover
      config={{
        align: "start",
        side: "bottom",
      }}
      triggerRender={() => (
        <div>
          <div
            className={twMerge(
              "relative flex flex-row items-center text-nowrap rounded-md border border-gray-200 bg-white px-2 py-1 text-xs hover:border-hover hover:text-hover focus:border-transparent focus-visible:outline-none"
            )}
          >
            <Icon name="calendar" className="mr-2 h-4 w-4" />
            {!!dateFrom && !!dateTo ? (
              <span>{`${format(dateFrom, { shortDate: true })} - ${format(dateTo, { shortDate: true })}`}</span>
            ) : (
              <span>{t("ui:date_range_picker.entire_period")}</span>
            )}
          </div>
        </div>
      )}
    >
      {(close) => (
        <DateRangeSelect
          onChange={onChange}
          onClose={close}
          dateFrom={dateFrom}
          dateTo={dateTo}
          resetLabel={resetLabel}
          onReset={onReset}
          allowUnset={allowUnset}
        />
      )}
    </Popover>
  );
}
