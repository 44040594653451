import { PageLayout } from "~/lib/ui/page-layout";
import { RichTextEditor } from "~/lib/ui/rich-text-editor";

export default function Editor() {
  return (
    <PageLayout title="Lexical Editors">
      <div className="flex flex-col gap-8">
        <div>
          <h2>Lexical Rich Text Editor</h2>
          <RichTextEditor />
        </div>
        <div>
          <h2>Lexical Plain Text Editor</h2>
          <RichTextEditor disableRichText={true} />
        </div>
      </div>
    </PageLayout>
  );
}
