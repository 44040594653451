import Calendar from "~/lib/ui/calendar/calendar";
import { datesInRange } from "~/lib/utils/date/date-utils";
import { Button, Icon, Label } from "~/lib/ui";
import { getPresetDates } from "~/lib/ui/calendar/date-presets";
import { DateRange } from "~/lib/utils/date/date-utils.types";
import { useTranslation } from "react-i18next";
import { useLocale } from "~/lib/utils/date";

type DateRangeSelectProps = {
  onChange: (range: DateRange) => void;
  onClose: () => void;
  dateFrom?: Date;
  dateTo?: Date;
  resetLabel?: string;
  onReset?: () => void;
  allowUnset?: boolean;
};

export function DateRangeSelect({
  dateFrom,
  dateTo,
  onChange,
  onClose,
  resetLabel,
  onReset,
  allowUnset,
}: DateRangeSelectProps) {
  const { t } = useTranslation();
  const { format } = useLocale();

  const handlePresetChange = async (range: DateRange) => {
    // Make absolutely sure that the popover is closed before calling onChange
    await new Promise<void>((resolve) => {
      onClose();
      resolve();
    }).then(() => onChange(range));
  };

  const presets = [
    {
      label: t("ui:date_range_picker.today", "Today"),
      onClick: () => handlePresetChange(getPresetDates("today")),
    },
    {
      label: t("ui:date_range_picker.yesterday", "Yesterday"),
      onClick: () => handlePresetChange(getPresetDates("yesterday")),
    },
    {
      label: t("ui:date_range_picker.this_week", "This week"),
      onClick: () => handlePresetChange(getPresetDates("this_week", new Date())),
    },
    {
      label: t("ui:date_range_picker.last_week", "Last week"),
      onClick: () => handlePresetChange(getPresetDates("last_week")),
    },
    {
      label: t("ui:date_range_picker.this_month", "This month"),
      onClick: () => handlePresetChange(getPresetDates("this_month", new Date())),
    },
    {
      label: t("ui:date_range_picker.last_month", "Last month"),
      onClick: () => handlePresetChange(getPresetDates("last_month")),
    },
  ];

  return (
    <div className="relative flex flex-col-reverse gap-4 rounded-lg border bg-white shadow-md sm:static sm:flex-col lg:flex-row">
      <div
        onClick={onClose}
        className="absolute right-2 top-2 flex h-10 w-10 items-center justify-center rounded-full sm:hidden"
      >
        <Icon name="close" className="h-5 w-5" />
      </div>
      <div className="flex flex-col gap-4 p-4 sm:flex-row sm:pb-0 lg:pb-4 lg:pr-0">
        <div className="flex shrink-0 flex-col gap-2">
          <Label className="flex items-center gap-2">
            <span>{t("common:from")}</span>
            {!!dateFrom && (
              <span className="items-center rounded-md border border-gray-200 bg-white px-2 py-1 text-xs">
                {format(dateFrom, { shortDate: true })}
              </span>
            )}
          </Label>
          <Calendar
            defaultDate={dateFrom}
            onSelect={(d) => onChange({ from: d, to: dateTo })}
            disabledAfter={dateTo}
            highlightedDates={dateFrom && dateTo ? datesInRange(dateFrom, dateTo, true) : undefined}
          />
        </div>
        <div className="flex shrink-0 flex-col gap-2">
          <Label className="flex items-center gap-2">
            <span>{t("common:to")}</span>
            {!!dateTo && (
              <span className="items-center rounded-md border border-gray-200 bg-white px-2 py-1 text-xs">
                {format(dateTo, { shortDate: true })}
              </span>
            )}
          </Label>
          <Calendar
            defaultDate={dateTo}
            onSelect={(d) => onChange({ from: dateFrom, to: d })}
            disabledAfter={new Date()}
            disabledBefore={dateFrom}
            highlightedDates={dateFrom && dateTo ? datesInRange(dateFrom, dateTo, true) : undefined}
          />
        </div>
      </div>
      <div className="flex max-w-[--radix-popover-content-available-width] flex-col justify-between p-4 sm:pt-0 lg:pl-0 lg:pt-4">
        <div className="flex shrink-0 flex-col gap-2 lg:min-w-32">
          <Label>{t("common:shortcut", { count: 2 })}</Label>
          <div className="flex flex-wrap gap-2 lg:flex-col">
            {allowUnset && (
              <Button
                variant="secondary"
                size="small"
                onClick={() => onChange({ from: undefined, to: undefined })}
              >
                {t("ui:date_range_picker.entire_period")}
              </Button>
            )}
            {onReset && (
              <Button variant="secondary" size="small" onClick={onReset}>
                {resetLabel ?? t("common:reset")}
              </Button>
            )}
            {presets.map((preset, idx) => (
              <Button key={idx} variant="secondary" size="small" onClick={preset.onClick}>
                {preset.label}
              </Button>
            ))}
          </div>
        </div>
        <div className="hidden justify-end pt-2 sm:flex">
          <Button variant="primary" size="small" onClick={onClose}>
            {t("common:close")}
          </Button>
        </div>
      </div>
    </div>
  );
}
