import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { useAPI } from "~/lib/api";
import { EntityTable } from "~/lib/entity-ui";
import { getResolvedLanguage } from "~/lib/i18n/i18n";
import {
  linkToExpense,
  linkToForm,
  linkToInvoice,
  linkToProjectForm,
  usePagination,
} from "~/lib/utils";
import { formatDate } from "~/lib/utils/date";
import { useProjectParams } from "../../_cmp/use-project-params";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { IconProp } from "~/lib/ui/types";
import { useQueryClient } from "@tanstack/react-query";
import Switch from "~/lib/ui/switch";
import { FilterGroupEmployees } from "~/lib/ui/filters/filter-group-employees";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { FilterGroup } from "~/lib/ui/filters/filter-group";
import { twMerge } from "tailwind-merge";
import { useFeatureFlags } from "~/lib/feature-flags";
import { ReactNode, useState } from "react";
import { Dialog, getIcon } from "~/lib/ui";
import { SendFormsDialog } from "./_cmp/send-form-dialog";
import { ProjectType } from "../../[id]";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import { BadgeLabel } from "~/lib/ui/badge-label";

export const BADGE_CLASS =
  "inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20";

export const BADGE_RED = "text-red-700 text-red-700 ring-red-600/20 bg-red-50";
export const BADGE_GRAY = "text-gray-700 text-gray-700 ring-gray-600/20 bg-gray-50";
export const BADGE_BLUE = "text-blue-700 text-blue-700 ring-blue-600/20 bg-blue-50";

const DEFAULT_PAGE_SIZE = 200;

export default function ProjectRegistrationsPage() {
  const [formDrafts, setFormDrafts] = useState<Array<string>>(); // For sending forms
  const { id: projectId } = useParams<{ id: string }>();
  const { entity: project } = useOutletContext<{
    entity: ProjectType;
  }>();
  const { t } = useTranslation();
  const api = useAPI();
  const [pagination] = usePagination();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const flags = useFeatureFlags();
  const [registrationFilters, setRegistrationFilters] = useTypedSearchParams<{
    employees?: Array<string>;
    sort?: string;
    documentType: string;
    approved?: boolean;
    direction: "desc" | "asc";
  }>();
  const projectParams = useProjectParams();
  const [previewFormURL, setPreviewFormURL] = useState<string | undefined>();

  const CACHE_KEY = ["registrations", projectId as string, pagination, projectParams];

  async function handleApproveAction(formIds: Array<string>, checked: boolean) {
    const res = await api.changeStatus({
      projectId: projectId as string,
      changeStatusRequest: {
        approve: checked,
        forms: formIds,
      },
    });
    refreshData();
    return res;
  }

  async function handleDeleteAction(formIds: Array<string>) {
    const promises = formIds.map((formId) => api.deleteForm({ formId: formId }));
    await Promise.all(promises);
    refreshData();
  }

  function refreshData() {
    queryClient.invalidateQueries({
      queryKey: [CACHE_KEY[0]],
    });
  }

  async function handleOpenSendDialog(forms: Array<string>) {
    setFormDrafts(forms);
  }

  return (
    <>
      <EntityTable
        dataFn={() =>
          api.getAllRegistrations({
            projectId: projectId as string,
            page: pagination.page,
            dateFrom: projectParams?.startDate,
            dateTo: projectParams?.endDate,
            limit: pagination.limit ?? DEFAULT_PAGE_SIZE,
            userId: registrationFilters.employees?.[0],
            sort: registrationFilters.sort,
            approved: registrationFilters.approved,
            documentType: registrationFilters.documentType,
            direction: registrationFilters.direction,
            activityIds: projectParams.activities ?? undefined,
          })
        }
        cacheKey={CACHE_KEY}
        showSearch={false}
        selection={{
          isDisabled: (item) => item.type !== "form" || item.deleted,
          actions: [
            {
              icon: getIcon("send"),
              label: t("common:send"),
              onExecute: (forms) => handleOpenSendDialog(forms.map((form) => form.id)),
              hideToast: true,
            },
            {
              icon: getIcon("approve"),
              label: t("common:approve", "Approve"),
              onExecute: (forms) =>
                handleApproveAction(
                  forms.map((form) => form.id),
                  true
                ),
            },
            {
              icon: getIcon("reject"),
              label: t("common:reject", "Reject"),
              onExecute: (forms) =>
                handleApproveAction(
                  forms.map((form) => form.id),
                  false
                ),
            },
          ],
        }}
        fields={{
          date: {
            label: t("common:date"),
            sortBy: "date",
            renderColumn: (item) => (
              <time dateTime={item.date?.toISOString()}>
                {formatDate(item.date, getResolvedLanguage(), { shortDate: true })}
              </time>
            ),
          },
          body: {
            label: t("common:type"),
            sortBy: "type",
            renderColumn: (item) => {
              let name: ReactNode | undefined =
                item.template ?? t(`common:${item.type}`, { count: 1 });
              let link: string | undefined = undefined;
              let Icon: IconProp | undefined = getIcon("unknownType");
              if (item.type === "form") {
                link = flags.has("cp3.forms")
                  ? linkToProjectForm(projectId!, item.id)
                  : linkToForm(item.id, "edit");
                Icon = getIcon("registration");
                name = (
                  <>
                    {item.template}
                    <div className="mt-2 block text-gray-400">{item.workDescription}</div>
                  </>
                );
              }
              if (item.type === "invoice") {
                link = `/companies/invoices/edit/${item.id}`;
                if (item.invoiced) {
                  link = `/companies/invoices/getPDF/${item.id}`;
                }

                Icon = getIcon("invoice");
              }
              if (item.type === "expense") {
                link = `/companies/expenses/edit/${item.id}`;
                Icon = getIcon("expense");
                name = (
                  <>
                    {t(`common:${item.type}`, { count: 1 })}
                    <div className="mt-2 block text-gray-400">{item.vendorName}</div>
                  </>
                );
              }

              return (
                <div className="flex flex-row items-center justify-between">
                  <OptionalLink to={link} openInNewTab={link?.includes("/companies/")}>
                    <span>
                      <Icon className="mr-2 inline h-5 w-5" aria-hidden="true" />
                      <span>{name}</span>
                    </span>
                  </OptionalLink>
                  <div className="flex gap-x-2">
                    {item.invoiced ? (
                      <span className={BADGE_CLASS}>{t("common:invoiced", "Invoiced")}</span>
                    ) : null}
                    {item.approved ? (
                      <span className={BADGE_CLASS}>{t("common:approved")}</span>
                    ) : null}
                    {item.deleted && (
                      <span className={twMerge(BADGE_CLASS, BADGE_RED)}>{t("common:deleted")}</span>
                    )}
                  </div>
                </div>
              );
            },
          },
          activites: {
            label: t("common:activity", { count: 2 }),
            renderColumn: (item) =>
              item.activity && (
                <BadgeLabel backgroundColor={`#${item.activity?.hexCode}`}>
                  {item.activity?.name}
                </BadgeLabel>
              ),
            collapse: {
              on: "body",
              breakpoint: "xl",
            },
          },
          employee: {
            label: t("common:employee", { count: 1 }),
            renderColumn: (item) => item.employee,
          },
          approved: {
            label: t("common:approved"),
            tdClass: "text-center",
            renderColumn: (item) =>
              item.type === "form" && (
                <Switch
                  className="inline"
                  checked={item.approved}
                  controlled
                  onCheckedChange={(checked) => {
                    handleApproveAction([item.id], checked);
                  }}
                />
              ),
          },
        }}
        actions={[
          {
            label: t("common:preview"),
            icon: getIcon("preview"),
            onExecute: async (row) => {
              if (row.type !== "form") return;
              if (!row.preview) return;
              setPreviewFormURL(row.preview);
            },
            hideToast: true,
            isVisible: (row) => {
              if (row.type !== "form") return false;
              if (row.deleted) return false;
              if (!row.preview) return false;
              return true;
            },
          },
          {
            label: t("common:edit"),
            icon: getIcon("edit"),
            onExecute: async (row) => {
              switch (row.type) {
                case "form":
                  if (flags.has("cp3.forms")) {
                    navigate(linkToProjectForm(projectId!, row.id));
                  } else {
                    navigate(linkToForm(row.id, "edit", { projectId: projectId }));
                  }
                  break;
                case "invoice":
                  navigate(linkToInvoice(row.id, "edit"));
                  break;
                case "expense":
                  navigate(linkToExpense(row.id, "edit"));
                  break;
              }
            },
            isVisible: (row) => !row.deleted,
          },
          {
            label: t("common:undelete"),
            icon: getIcon("undelete"),
            onExecute: async (row) => {
              await api.undeleteForm({ formId: row.id, projectId: projectId as string });
              refreshData();
            },
            isVisible(row) {
              return row.deleted;
            },
          },
          {
            icon: getIcon("delete"),
            label: t("common:delete"),
            onExecute: (form) => handleDeleteAction([form.id]),
            mustConfirm: true,
            isVisible: (row) => row.type === "form" && !row.deleted,
          },
        ]}
        filters={(data) => {
          return (
            <>
              <FilterGroupEmployees
                value={registrationFilters.employees}
                allowMultiple={false} // Endpoint doesn't support multiple
                onConfirmSelection={(selectedUsers) => {
                  setRegistrationFilters(
                    "employees",
                    selectedUsers.map((u) => u.id)
                  );
                }}
                onClear={() => setRegistrationFilters("employees", undefined)}
              />
              <FilterGroup
                name={t("projects:document_type")}
                isLoading={!data}
                value={registrationFilters.documentType}
                selection={{
                  items: data?.documentTypes ?? [],
                  valueFn: (item) => {
                    switch (item.type) {
                      case "form":
                        return item.formTemplateId?.toString();
                      default:
                        return item.type;
                    }
                  },
                  allowMultiple: false,
                  renderSelectionItem: (item) => {
                    switch (item.type) {
                      case "form":
                        return item.formTitle;
                      default:
                        return t(`common:${item.type}`, { count: 1 });
                    }
                  },
                  onConfirmSelection: (selected) => {
                    const docType =
                      selected[0].type === "form" ? selected[0].formTemplateId : selected[0].type;
                    setRegistrationFilters("documentType", docType ?? t("common:not_available"));
                  },
                }}
                onClear={() => setRegistrationFilters("documentType", undefined)}
              />
              <FilterGroup
                name={t("common:approved")}
                isLoading={!data}
                value={registrationFilters.approved}
                selection={{
                  items: [
                    { value: true, label: t("common:yes") },
                    { value: false, label: t("common:no") },
                  ],
                  valueFn: (item) => item.value,
                  renderSelectionItem(item) {
                    return item.label;
                  },
                  onConfirmSelection: (selection) => {
                    setRegistrationFilters("approved", selection[0].value);
                  },
                }}
                onClear={() => setRegistrationFilters("approved", undefined)}
              />
            </>
          );
        }}
      />
      <Dialog
        open={!!formDrafts}
        onOpenChange={() => setFormDrafts(undefined)}
        render={({ onClose }) => (
          <SendFormsDialog
            projectId={projectId as string}
            onClose={onClose}
            defaultEmail={project.contactPerson?.email ?? project.contact?.email ?? ""}
            defaultSubject={t("projects:send_registrations_subject", {
              replace: { workaddress: project.streetName || "projekt" },
              defaultValue: "Documentation for {{workaddress}}",
            })}
            defaultMessage={t("projects:send_registrations_message", {
              replace: { name: project.contactPerson?.name ?? project.contact?.name ?? "" },
            })}
            formIds={formDrafts ?? []}
          />
        )}
      />
      <FullScreenFilePreview
        open={!!previewFormURL}
        fileUrl={previewFormURL}
        onClose={() => setPreviewFormURL(undefined)}
      />
    </>
  );
}
