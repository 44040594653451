import { PencilIcon } from "@heroicons/react/24/outline";
import DateInput from "~/lib/ui/calendar/date-input";
import { useState } from "react";
import { Company, Contact, ContactPerson } from "@apacta/sdk";
import { formatDate } from "~/lib/utils/date";
import { useTranslation } from "react-i18next";
import { useMount } from "~/lib/lifecycle-helpers";
import { Customer } from "~/lib/customer/types";
import { twMerge } from "tailwind-merge";
import WorkAddressInput from "~/pages/offers/_cmp/work-address-input";
import { OfferFormState } from "~/pages/offers/_cmp/state/use-offer-form-state";
import { CustomerSelection } from "~/lib/ui/selection-combobox/customer-selection";
import { ContactPersonSelection } from "~/lib/ui/selection-combobox/contact-person-selection";

export default function HeaderSection({
  selectedCustomer,
  selectedContactPerson,
  editMode,
  companyData,
  formState,
  offerNumber,
  onSelectCustomer,
  onSelectContactPerson,
}: {
  selectedCustomer?: Customer | null;
  selectedContactPerson?: ContactPerson | null;
  editMode: boolean;
  companyData: Company;
  formState: OfferFormState;
  offerNumber?: number | null;
  onSelectCustomer?: (c?: Customer | null) => void;
  onSelectContactPerson?: (c?: ContactPerson | null) => void;
}) {
  const { t, i18n } = useTranslation();
  const [replaceCustomerOpen, setReplaceCustomerOpen] = useState<boolean>(false);
  const [replaceContactPersonOpen, setReplaceContactPersonOpen] = useState<boolean>(false);

  const handleSelectCustomer = (customer: Contact | null) => {
    if (customer !== selectedCustomer) {
      onSelectCustomer?.(customer);
    }

    setReplaceCustomerOpen(false);
  };

  const handleSelectContactPerson = (customerContact: ContactPerson | null) => {
    if (customerContact !== selectedContactPerson) {
      onSelectContactPerson?.(customerContact);
    }

    setReplaceContactPersonOpen(false);
  };

  function toDataURL(url: string, callback: (res: string) => void) {
    const xhr = new XMLHttpRequest();
    xhr.onload = async function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result?.toString() ?? "");
      };
      await reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  const [companyLogo, setCompanyLogo] = useState<string>("");

  useMount(() => {
    if (companyData.file) {
      toDataURL(companyData.file.fileUrl, (data) => {
        setCompanyLogo(data);
      });
    }
  });

  return (
    <div className="flex flex-col gap-8">
      <div className="flex justify-end">
        {companyData && <img alt={companyData.name} src={companyLogo} className="h-auto w-52" />}
      </div>
      <div className="sm:flex sm:items-start">
        <div className="flex flex-auto flex-col gap-8">
          <div className="flex items-center gap-2">
            {selectedCustomer && !replaceCustomerOpen && editMode && (
              <div
                onClick={() => setReplaceCustomerOpen(true)}
                className="-ml-10 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-tertiary p-1 text-white hover:bg-tertiary-hover"
              >
                <PencilIcon className="h-4 w-4" />
              </div>
            )}
            <div className={twMerge("flex w-full flex-col", editMode ? "pl-2" : "")}>
              {selectedCustomer && !replaceCustomerOpen ? (
                <>
                  <span className="font-bold">{selectedCustomer.name}</span>
                  <span className={selectedCustomer.address ? "" : "text-gray-400"}>
                    {selectedCustomer.address ? selectedCustomer.address : "Ikke tilgængelig"}
                  </span>
                  <span
                    className={
                      selectedCustomer.zipCode && selectedCustomer.cityName ? "" : "text-gray-400"
                    }
                  >
                    {selectedCustomer.zipCode && selectedCustomer.cityName
                      ? `${selectedCustomer.zipCode} ${selectedCustomer.cityName}`
                      : t("common:not_available")}
                  </span>
                </>
              ) : (
                <div className="w-3/4">
                  {editMode && (
                    <>
                      <CustomerSelection
                        onSelect={(_, c) => handleSelectCustomer(c)}
                        defaultValue={selectedCustomer?.id}
                      />

                      {replaceCustomerOpen && (
                        <div className="flex justify-end">
                          <span
                            className="cursor-pointer p-2 underline"
                            onClick={() => setReplaceCustomerOpen(false)}
                          >
                            {t("common:cancel")}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-2">
            {selectedContactPerson && !replaceContactPersonOpen && editMode && (
              <div
                onClick={() => setReplaceContactPersonOpen(true)}
                className="-ml-10 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-tertiary p-1 text-white hover:bg-tertiary-hover"
              >
                <PencilIcon className="h-4 w-4" />
              </div>
            )}
            <div className={twMerge("flex w-full flex-col", editMode ? "pl-2" : "")}>
              {selectedContactPerson && !replaceContactPersonOpen ? (
                <>
                  <span className="font-bold">
                    {t("common:att")}:{" "}
                    <span className="font-normal">{selectedContactPerson.name}</span>
                  </span>
                </>
              ) : (
                <div className="w-3/4">
                  {editMode && (
                    <>
                      <ContactPersonSelection
                        controlled
                        value={selectedContactPerson?.id}
                        onSelect={(_, c) => handleSelectContactPerson(c)}
                        customerId={selectedCustomer?.id}
                        disabled={!selectedCustomer}
                      />
                      {replaceContactPersonOpen && (
                        <div className="flex justify-end">
                          <span
                            className="cursor-pointer p-2 underline"
                            onClick={() => setReplaceContactPersonOpen(false)}
                          >
                            {t("common:cancel")}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <WorkAddressInput formState={formState} editMode={editMode} />
        </div>
        <div className="flex flex-col items-end gap-4">
          <div className="flex flex-col items-end">
            <span className="font-bold">{companyData.name}</span>
            <span>{companyData.streetName}</span>
            <span>
              {companyData.city?.zipCode} {companyData.city?.name}
            </span>
            <span>
              {t("common:cvr_abbr")}: {companyData.cvr}
            </span>
            <span>
              {t("common:phone_number_abbr")}: +{companyData.phoneCountrycode} {companyData.phone}
            </span>
            <span>{companyData.contactEmail}</span>
          </div>
          <div className={twMerge("flex flex-col items-end", editMode ? "gap-2" : "")}>
            <div className="flex items-center gap-2">
              <div>
                {t("offers:offer_number", { type: t(`offers:${formState.getValue("type")}`) })}:
              </div>
              {offerNumber ? (
                <div>{offerNumber}</div>
              ) : (
                <div className="text-gray-400">{t("common:not_available")}</div>
              )}
            </div>
            <div className="flex items-center gap-2">
              <span>{t("common:expiration_date")}:</span>
              {editMode && (
                <DateInput
                  name="expiration-date"
                  value={formState.getValue("expirationDate")}
                  onChange={(d) => formState.setValues({ expirationDate: d })}
                  label=""
                  className="w-40"
                  disabledBefore={new Date()}
                />
              )}
              {!editMode && (
                <span>
                  {formatDate(formState.getValue("expirationDate"), i18n.resolvedLanguage, {
                    shortDate: true,
                  })}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
