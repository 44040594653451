import {
  ForwardedRef,
  forwardRef,
  Fragment,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { useTranslation } from "react-i18next";
import { OrderLinesBuilderTableDragLayer } from "~/lib/ui/order-lines/table/order-lines-builder-table-drag-layer";
import {
  LineRef,
  OrderLinesBuilderTableRow,
} from "~/lib/ui/order-lines/table/order-lines-builder-table-row";
import { OrderlinesBuilderTableFooter } from "~/lib/ui/order-lines/table/orderlines-builder-table-footer";
import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";
import {
  OrderLine,
  OrderLineAdd,
  OrderLinesBuilderRef,
  OrderLinesBuilderTableProps,
  OrderLinesColumnVisibilityTypes,
} from "~/lib/ui/order-lines/lib/types";
import { flushSync } from "react-dom";

export const OrderLinesBuilderTable = forwardRef<OrderLinesBuilderRef, OrderLinesBuilderTableProps>(
  OrderLinesBuilderTableInner
);

export function OrderLinesBuilderTableInner(
  {
    lines,
    isChild = false,
    depth = 0,
    parentId,
    editMode = false,
    companyVatPercent,
    showAll,
    orderLinesColumnVisibility,
    orderLinesActionVisibility,
    overwriteSubAmount,
    hideProductBundleContent = false,
    maxHeight,
    buttonSizes = "medium",
    popoverOptions,
    usePlainTextEditor,
  }: OrderLinesBuilderTableProps,
  ref: ForwardedRef<OrderLinesBuilderRef>
) {
  const [focusLineId, setFocusLineId] = useState<string>();
  const { vat, addLine, isModified, isValid, resetInitialValues, setViewOrderLines } =
    useOrderLinesBuilder();
  const lineRefs = useRef<{ [key: string]: LineRef }>({});
  const [hasDiscountedLines, setHasDiscountedLines] = useState<boolean>(false);
  const defaultOrderLinesColumnVisibility: OrderLinesColumnVisibilityTypes = {
    showHandle: true,
    showProduct: true,
    showQuantity: true,
    showDiscount: true,
    showCostPrice: true,
    showSalePrice: true,
    showTotal: true,
    showActions: true,
  };
  const columnVisibility = {
    ...defaultOrderLinesColumnVisibility,
    ...orderLinesColumnVisibility,
  };

  //TODO - Move the entire view portion to its own component for display only

  useImperativeHandle(ref, () => ({
    getLines: () => lines,
    getVat: () => vat,
    isModified: () => isModified,
    isValid: () => isValid,
    resetInitialValues: () => resetInitialValues(),
    setViewLines: (l: Array<Partial<OrderLine>>) => flushSync(() => setViewOrderLines(l)),
  }));

  const { t } = useTranslation();

  const handleAddOfferLine = (opts: OrderLineAdd) => {
    const details = addLine(opts);
    if (details.isDuplicate) {
      // If the line is a duplicate, focus the line immediately
      lineRefs.current[details.focusLineId]?.focus(true);
    } else {
      // If the line is not a duplicate, set the focusLineId to be focused after the render
      setFocusLineId(details.focusLineId);
    }
  };

  useLayoutEffect(() => {
    // Focus the newly added line after the render
    if (focusLineId) {
      const lineRef = lineRefs.current[focusLineId];
      if (lineRef) {
        lineRef.focus();
        setFocusLineId(undefined);
      }
    }
    // Check if the lines contain any discounted lines
    setHasDiscountedLines(lines.some((line) => !!line.discount));
  }, [lines]);

  const handleRef = (lineRef: LineRef | null, line: Partial<OrderLine>) => {
    if (lineRef) {
      lineRefs.current[line.identifier as string] = lineRef;
    }
  };

  // Extracted weights from each column for calculating width (former w-[Weight]/24)
  const weights: { [key: string]: number } = {
    showHandle: editMode ? 1 : 0,
    showProduct: editMode ? 7 : 9,
    showQuantity: 2,
    showCostPrice: 3,
    showSalePrice: editMode ? 3 : 5,
    showDiscount: editMode ? 4 : 3,
    showTotal: 3,
    showActions: editMode ? 1 : 0,
  };

  // Distribute width based on weights and visibility to always have nicely aligned columns
  const visibleColumns = Object.entries(columnVisibility).filter(([key, value]) => value);
  const totalWeight = visibleColumns.reduce((acc, [key, value]) => acc + weights[key], 0);
  const columnWidths = visibleColumns.reduce<Record<string, string>>((acc, [key, value]) => {
    acc[key] = `${(weights[key] / totalWeight) * 100}%`;
    return acc;
  }, {});

  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <OrderLinesBuilderTableDragLayer />
      <div className="flex w-full flex-col">
        {!isChild && (
          <div
            className="flex w-full border-b border-gray-300 font-semibold"
            data-pdf-rule="repeat-on-split"
          >
            {editMode && columnVisibility.showHandle && <div className="w-1/24 p-3"></div>}
            {columnVisibility?.showProduct && (
              <div className="p-3" style={{ width: columnWidths["showProduct"] }}>
                {t("common:product", { count: 1 })}
              </div>
            )}
            {columnVisibility?.showQuantity && (
              <div className="p-3 text-right" style={{ width: columnWidths["showQuantity"] }}>
                {t("common:quantity")}
              </div>
            )}
            {columnVisibility?.showCostPrice && editMode && (
              <div
                className="p-3 text-right"
                style={{ width: columnWidths["showCostPrice"] }}
              >{`${t("common:cost_price")} (${t("common:each_abbr")})`}</div>
            )}
            {columnVisibility?.showSalePrice && (
              <div className="p-3 text-right" style={{ width: columnWidths["showSalePrice"] }}>
                {`${t("common:price")} (${t("common:each_abbr")})`}
              </div>
            )}
            {columnVisibility?.showDiscount && (editMode || hasDiscountedLines) && (
              <div className="p-3 text-right" style={{ width: columnWidths["showDiscount"] }}>
                {t("common:discount")}
              </div>
            )}

            {columnVisibility?.showTotal && (
              <div className="flex flex-grow justify-end p-3 text-right">{t("common:amount")}</div>
            )}
            {editMode && (
              <div
                style={{ width: columnWidths["showActions"] }}
                className={twMerge("p-3", columnVisibility?.showTotal ? "" : "grow")}
              ></div>
            )}
          </div>
        )}
        <div
          className={twMerge(!!maxHeight ? maxHeight : "", "planning-scrollbar overflow-y-auto")}
        >
          {lines.map((line) => {
            const showChildren =
              !hideProductBundleContent &&
              !!line.childOfferLines &&
              line.childOfferLines.length > 0 &&
              (editMode || !!showAll);
            return (
              <Fragment key={`offer-line-${line.identifier}`}>
                <OrderLinesBuilderTableRow
                  ref={(r) => handleRef(r, line)}
                  line={line}
                  editMode={editMode}
                  depth={depth}
                  parentId={parentId}
                  orderLinesColumnVisibility={
                    hasDiscountedLines || editMode
                      ? columnVisibility
                      : { ...columnVisibility, showDiscount: false }
                  }
                  columnWidths={columnWidths}
                  usePlainTextEditor={usePlainTextEditor}
                />
                {showChildren && line.childOfferLines ? (
                  <OrderLinesBuilderTable
                    lines={line.childOfferLines}
                    parentId={line.identifier}
                    isChild={true}
                    depth={depth + 1}
                    editMode={editMode}
                    orderLinesColumnVisibility={columnVisibility}
                    usePlainTextEditor={usePlainTextEditor}
                  />
                ) : null}
              </Fragment>
            );
          })}
        </div>
        {!isChild && (
          <div data-pdf-rule="no-split">
            <OrderlinesBuilderTableFooter
              editMode={editMode}
              onAddOfferLine={handleAddOfferLine}
              companyVatPercent={companyVatPercent}
              options={{ orderLinesFooterVisibility: orderLinesActionVisibility, popoverOptions }}
              overwriteSubAmount={overwriteSubAmount}
              buttonSizes={buttonSizes}
            />
          </div>
        )}
      </div>
    </DndProvider>
  );
}
