import { PageLayout } from "~/lib/ui/page-layout";
import { Button } from "~/lib/ui";
import { useToasts } from "~/lib/toast/use-toasts";

export default function Toast() {
  const { showTemplate } = useToasts();

  const handleTriggerToast = () => {
    showTemplate("MESSAGE_SENT");
  };

  return (
    <PageLayout title="Toast Playground">
      <div className="">
        <Button variant="primary" onClick={() => handleTriggerToast()}>
          Trigger toast
        </Button>
      </div>
    </PageLayout>
  );
}
