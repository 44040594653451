import { PageLayout } from "~/lib/ui/page-layout";
import { EntityTable } from "~/lib/entity-ui";
import { IListProjectsDirectionEnum } from "@apacta/sdk";
import {
  ChevronDownIcon,
  DocumentDuplicateIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useNavigate } from "react-router";
import { usePagination } from "~/lib/utils/hooks";
import OfferStatusLabel from "~/lib/offers/offer-status-label";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { OfferStatusItem } from "~/lib/offers/use-offer-status";
import { useState } from "react";
import OfferStatusFilter from "~/pages/offers/_cmp/offer-status-filter";
import { PillBadge } from "~/lib/ui/pill-badge";
import { linkToCustomer } from "~/lib/utils";
import { formatDate } from "~/lib/utils/date";
import { formatCurrency } from "~/lib/utils/number";
import OffersKpiCards from "~/pages/projects/[id]/_cmp/offers-kpi-cards";
import { Button } from "~/lib/ui";

export default function OffersPage() {
  const api = useAPI();
  const navigate = useNavigate();
  const [pagination, setPagination] = usePagination();

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const queryKeys = ["offers-kpi-cards", "offers"];
  const [filterStatus, setFilterStatus] = useState<OfferStatusItem | undefined>();

  const handleCreateOffer = (): void => {
    api
      .createOffer({ editOfferRequest: { title: t("offers:new_offer"), isQuote: true } })
      .then((v) => {
        if (v.data?.id) {
          navigate(v.data.id);
        }
      });
  };

  const handleDuplicateOffer = async (id: string): Promise<void> => {
    await duplicateMutation.mutateAsync(id);
  };

  const handleDeleteOffer = async (id: string): Promise<void> => {
    await api.iDeleteOffer({ offerId: id });
    await queryClient.invalidateQueries({
      queryKey: queryKeys,
    });
  };

  const duplicateMutation = useMutation({
    mutationFn: (id: string) => api.duplicateOffer({ offerId: id }),
    onSuccess: async (res) => {
      if (res.data?.id) {
        await queryClient.invalidateQueries({
          queryKey: queryKeys,
        });
        navigate(res.data.id);
      }
    },
  });

  return (
    <PageLayout
      title={t("navigation:offers.title")}
      renderActions={() => (
        <Button
          Icon={PlusIcon}
          variant="tertiary"
          className="print:hidden"
          onClick={handleCreateOffer}
        >
          {t("common:create", {
            defaultValue: "Create {{entity}}",
            replace: { entity: t("common:offer_quote", { count: 1 }).toLocaleLowerCase() },
          })}
        </Button>
      )}
    >
      <OffersKpiCards />
      <EntityTable
        cacheKey={["offers", filterStatus?.id ?? ""]}
        dataFn={() =>
          api.offersList({
            page: pagination.page,
            limit: pagination.limit,
            sort: pagination.sort,
            q: pagination.q,
            direction: pagination.direction as IListProjectsDirectionEnum,
            offerStatusId: filterStatus?.id,
          })
        }
        fields={{
          offerNumber: {
            label: t("common:nr"),
            thClass: "w-12",
            renderColumn: (row) => <span className="font-semibold">{row.offerNumber}</span>,
            sortBy: "offer_number",
            href: (row) => `/offers/${row.id}`,
          },
          name: {
            label: t("offers:offer_name"),
            renderColumn: (row) => <span>{row.title}</span>,
            sortBy: "title",
            href: (row) => `/offers/${row.id}`,
          },
          customer: {
            label: t("common:customer", { count: 1 }),
            renderColumn: (row) => <span>{row.contact?.name}</span>,
            href: (row) => (row.contact ? linkToCustomer(row.contact.id) : undefined),
          },
          expiration: {
            label: t("common:expiration_date"),
            renderColumn: (row) =>
              row.expirationDate ? (
                <span>
                  {formatDate(row.expirationDate, i18n.resolvedLanguage, { shortDate: true })}
                </span>
              ) : (
                <span className="text-gray-400">{t("common:not_available")}</span>
              ),
          },
          amount: {
            label: t("finance:sales_price"),
            renderColumn: (row) => <span>{formatCurrency(row.netPayment ?? 0)}</span>,
          },
          status: {
            label: t("common:status"),
            thClass: "text-center",
            renderColumn: (row) => (
              <div className="flex justify-center">
                <OfferStatusLabel selectedStatusId={row.offerStatusId} readonly />
              </div>
            ),
          },
        }}
        filters={(data) => {
          return (
            <>
              <OfferStatusFilter
                triggerRender={() => (
                  <PillBadge
                    label={t("common:status")}
                    isLoading={!data}
                    value={filterStatus?.translatedLabel}
                    onAction={() => {
                      if (filterStatus) {
                        setFilterStatus(undefined);
                      }
                    }}
                    action={
                      filterStatus === undefined ? (
                        <ChevronDownIcon className="h-4 w-4" />
                      ) : (
                        <XMarkIcon className="h-3 w-3" />
                      )
                    }
                    className="outline-none hover:border-hover hover:text-hover focus:border-indigo-500 focus-visible:outline-hover"
                  />
                )}
                onSelect={(item) => setFilterStatus(item)}
              />
            </>
          );
        }}
        actions={[
          {
            icon: DocumentDuplicateIcon,
            label: t("common:duplicate"),
            mustConfirm: false,
            onExecute: (row) => handleDuplicateOffer(row.id),
          },
          {
            icon: TrashIcon,
            label: t("common:delete_prompt", { name: t("common:offer_quote").toLowerCase() }),
            mustConfirm: true,
            description: t("common:delete_description_prompt", {
              entity: t("common:offer_quote_specific").toLowerCase(),
            }),
            onExecute: (row) => handleDeleteOffer(row.id),
          },
        ]}
      />
    </PageLayout>
  );
}
