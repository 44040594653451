import * as DialogPrimitive from "@radix-ui/react-dialog";
import { forwardRef, ReactNode, Ref, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { DialogFooter } from "./dialog-footer";
import { DialogHeader } from "./dialog-header";
import { Icon } from "../icons/icon";
import { useLockBodyScroll } from "@uidotdev/usehooks";

export type DialogPropsWithTrigger = {
  open?: never;
  /**
   * Trigger element needs to be able to receive a ref
   */
  trigger: ReactNode; // If specified, it will be used
};

export type DialogPropsWithControlledOpen = {
  open: boolean;
  trigger?: never;
};

export type DialogProps = (DialogPropsWithTrigger | DialogPropsWithControlledOpen) & {
  className?: string;
  useOverlay?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  render: (props: { onClose: () => void }) => ReactNode;
};

/**
 * A window with an overlay and a dialog that can be opened and closed.
 * Any content can be rendered inside the dialog. The actual dialog should handle header/footer, due to loading state.
 */
export function Dialog(props: DialogProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false); // Not used if `open` prop is used
  const { useOverlay = true } = props;

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
    props.onOpenChange?.(isOpen);
  };

  return (
    <DialogPrimitive.Root open={props.open ?? open} onOpenChange={handleOpenChange}>
      {props.trigger ? (
        <DialogPrimitive.Trigger asChild>{props.trigger}</DialogPrimitive.Trigger>
      ) : null}

      <DialogPrimitive.Portal>
        {useOverlay && <CustomOverlay data-state={open ? "open" : closed} />}
        <DialogPrimitive.Content
          className={twMerge(
            "planning-scrollbar md:pt-unset h-full animate-fade-in overflow-y-auto px-8 pb-8 pt-24 duration-300 focus-visible:outline-none md:h-auto md:max-h-[95%] md:pt-8",
            "fixed left-0 top-0 w-full transform rounded-lg bg-white shadow-2xl md:left-1/2 md:top-1/2 md:max-w-2xl md:-translate-x-1/2 md:-translate-y-1/2",
            props.className
          )}
        >
          <div>{props.render({ onClose: () => handleOpenChange(false) })}</div>
          <div className="absolute right-0 top-16 block pr-4 pt-4 sm:top-0">
            <DialogPrimitive.Close asChild>
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-hover focus:ring-offset-2"
                onClick={() => handleOpenChange(false)}
              >
                <span className="sr-only">{`${t("common:close")} ${t(
                  "common:modal"
                ).toLowerCase()}`}</span>
                <Icon name="close" size="medium" aria-hidden="true" />
              </button>
            </DialogPrimitive.Close>
          </div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
}

// https://github.com/radix-ui/primitives/issues/1159#issuecomment-1563399450
const CustomOverlay = forwardRef(function CustomOverlay(_, ref: Ref<HTMLDivElement>) {
  useLockBodyScroll(); // Prevents body from scrolling, but not our modal
  return (
    <div ref={ref} className="fixed inset-0 animate-fade-in bg-black bg-opacity-30 duration-150" />
  );
});

Dialog.Footer = DialogFooter;
Dialog.Header = DialogHeader;
