import { useCalendar } from "../../calendar/use-calendar";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "../../lifecycle-helpers";
import { twMerge } from "tailwind-merge";
import { Button, Icon } from "~/lib/ui";
import CalendarDates from "~/lib/ui/calendar/calendar-dates";

export default function Calendar({
  defaultDate,
  onSelect,
  disabledBefore,
  disabledAfter,
  highlightedDates,
}: {
  defaultDate?: Date;
  onSelect?: (d: Date) => void;
  disabledBefore?: Date;
  disabledAfter?: Date;
  highlightedDates?: Array<Date>;
}) {
  const { t } = useTranslation();
  const { selectedDate, setSelectedDate, months, days } = useCalendar(defaultDate);

  useMount(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      const el = document.activeElement as HTMLElement;
      if (el.dataset.calendarItem) {
        switch (e.code) {
          case "ArrowUp":
          case "ArrowDown":
          case "ArrowLeft":
          case "ArrowRight":
            e.preventDefault();
            e.stopPropagation();
            break;
        }
      }
    };

    window.addEventListener("keydown", (e) => handleKeydown(e));

    return window.removeEventListener("keydown", handleKeydown);
  });

  useEffect(() => {
    if (!defaultDate) return;
    setSelectedDate(defaultDate);
  }, [defaultDate]);

  const switchMonth = useCallback(
    (o: number) => {
      const d = new Date(selectedDate);
      d.setDate(1);
      d.setMonth(d.getMonth() + o);
      setSelectedDate(d);
      return d;
    },
    [selectedDate]
  );

  const switchYear = useCallback(
    (o: number) => {
      const d = new Date(selectedDate);
      d.setDate(1);
      d.setFullYear(d.getFullYear() + o);
      setSelectedDate(d);
      return d;
    },
    [selectedDate]
  );

  return (
    <div className="w-full max-w-96 overflow-hidden rounded-lg bg-white text-center shadow-md sm:w-72">
      <div className="flex items-center justify-between bg-primary px-2 py-1 text-white">
        <div className="flex gap-2">
          <Button
            variant="primary"
            className="px-2 py-1.5 focus:bg-hover"
            onClick={() => switchYear(-1)}
            tabIndex={0}
          >
            <span className="sr-only">{t("ui:calendar.navigation.previous_month")}</span>
            <Icon name="chevronDoubleLeft" className="h-3 w-auto" />
          </Button>
          <Button
            variant="primary"
            className="px-2 py-1.5 focus:bg-hover"
            onClick={() => switchMonth(-1)}
            tabIndex={0}
          >
            <span className="sr-only">{t("ui:calendar.navigation.previous_month")}</span>
            <Icon name="chevronLeft" className="h-3 w-auto" />
          </Button>
        </div>
        <span className="flex items-center px-2 py-1 text-white sm:text-sm">
          {months[selectedDate.getMonth()].name} {selectedDate.getFullYear()}
        </span>
        <div className="flex gap-2">
          <Button
            variant="primary"
            className="px-2 py-1.5 focus:bg-hover"
            onClick={() => switchMonth(1)}
            tabIndex={0}
          >
            <span className="sr-only">{t("ui:calendar.navigation.next_month")}</span>
            <Icon name="chevronRight" className="h-3 w-auto" />
          </Button>
          <Button
            variant="primary"
            className="px-2 py-1.5 focus:bg-hover"
            onClick={() => switchYear(1)}
            tabIndex={0}
          >
            <span className="sr-only">{t("ui:calendar.navigation.next_month")}</span>
            <Icon name="chevronDoubleRight" className="h-3 w-auto" />
          </Button>
        </div>
      </div>
      <div className="relative overflow-y-hidden focus-visible:outline-none">
        <div className={twMerge("pt-2")}>
          <div className="grid grid-cols-8 leading-6 text-gray-500 sm:text-xs">
            <div>{t("common:week")}</div>
            {days.map((day) => (
              <div key={day.index}>{day.name}</div>
            ))}
          </div>
          <div className="relative isolate mt-2 grid grid-cols-8 gap-px rounded-lg bg-white shadow ring-1 ring-gray-200 sm:text-xs">
            <CalendarDates
              selectedDate={selectedDate}
              highlightedDates={highlightedDates}
              options={{
                defaultDate,
                onSelect,
                disabledBefore,
                disabledAfter,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
