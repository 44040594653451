import {
  Fragment,
  KeyboardEvent as ReactKeyboardEvent,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import { startOfDay } from "date-fns";
import { useSuspenseQuery } from "@tanstack/react-query";
import { CalendarDates } from "~/lib/calendar/calendar.types";
import { useCalendar } from "~/lib/calendar/use-calendar";
import { getDateWithoutTime } from "~/lib/utils/date/date-utils";

export type CalendarDatesMatrixOptions = {
  disabledBefore?: Date;
  disabledAfter?: Date;
  defaultDate?: Date;
  onSelect?: (d: Date) => void;
};

export default function CalendarDatesMatrix({
  selectedDate,
  highlightedDates,
  options,
}: {
  selectedDate: Date;
  highlightedDates?: Array<Date>;
  options: CalendarDatesMatrixOptions;
}) {
  const { initialDate, getDaysInMonth, setSelectedDate } = useCalendar(selectedDate);
  const calendarRef = useRef<Array<HTMLButtonElement>>([]);
  const [targetIndex, setTargetIndex] = useState<number | null>(null);

  const { onSelect, disabledBefore, disabledAfter, defaultDate } = options;

  const calendarData = useSuspenseQuery({
    queryKey: ["calendar", selectedDate.getFullYear(), selectedDate.getMonth()],
    queryFn: async () => {
      return new Promise<CalendarDates>((resolve) => {
        resolve(getDaysInMonth(selectedDate));
      });
    },
  });

  const calendarDates = calendarData.data;

  function isSelectedDate(val: Date): boolean {
    if (!selectedDate) return false;
    return (
      val.getFullYear() === selectedDate.getFullYear() &&
      val.getMonth() === selectedDate.getMonth() &&
      val.getDate() === selectedDate.getDate()
    );
  }

  function isHighlightedDate(val: Date): boolean {
    if (!highlightedDates || !highlightedDates.length) return false;
    return highlightedDates.some((hd) => {
      return (
        val.getFullYear() === hd.getFullYear() &&
        val.getMonth() === hd.getMonth() &&
        val.getDate() === hd.getDate()
      );
    });
  }

  useLayoutEffect(() => {
    if (calendarRef.current.length > 0) {
      calendarRef.current[
        targetIndex ??
          calendarDates?.days?.find(
            (d) =>
              getDateWithoutTime(d.date).getTime() === getDateWithoutTime(initialDate).getTime()
          )?.calendarIndex ??
          0
      ].focus();
    }
  }, [calendarDates.days]);

  const switchMonth = useCallback(
    (o: number) => {
      const d = new Date(selectedDate);
      d.setDate(1);
      d.setMonth(d.getMonth() + o);
      setSelectedDate(d);
      return d;
    },
    [selectedDate]
  );

  const switchYear = useCallback(
    (o: number) => {
      const d = new Date(selectedDate);
      d.setDate(1);
      d.setFullYear(d.getFullYear() + o);
      setSelectedDate(d);
      return d;
    },
    [selectedDate]
  );

  function handleKeyboardNavigation(el: HTMLElement, e: ReactKeyboardEvent<HTMLButtonElement>) {
    const navigateMonth = e.ctrlKey && !e.shiftKey;
    const navigateYear = e.shiftKey && e.ctrlKey;

    const navigate = (count: number) => {
      const selectedItemIndex = calendarRef.current.findIndex((b: HTMLButtonElement) => el === b);

      if (navigateMonth || navigateYear) {
        if (navigateMonth) {
          count < 0 ? switchMonth(-1) : switchMonth(1);
        } else {
          count < 0 ? switchYear(-1) : switchYear(1);
        }
        setTargetIndex(selectedItemIndex);
        return;
      }

      if (selectedItemIndex + count >= calendarRef.current.length) {
        return;
      }
      if (selectedItemIndex + count < 0) {
        return;
      }

      calendarRef.current[selectedItemIndex + count].focus();
    };

    switch (e.code) {
      case "ArrowUp":
        navigate(-7);
        break;
      case "ArrowDown":
        navigate(7);
        break;
      case "ArrowLeft":
        navigate(-1);
        break;
      case "ArrowRight":
        navigate(1);
        break;
    }
  }

  return (
    <>
      {calendarDates.days.map((day, dayIdx) => (
        <Fragment key={day.date.toLocaleString()}>
          {dayIdx % 7 === 0 && (
            <div
              className={twMerge(
                "mx-auto flex w-full items-center justify-center bg-zinc-200",
                dayIdx === 0 && "rounded-tl-lg"
              )}
            >
              {day.weekNumber}
            </div>
          )}
          <button
            type="button"
            data-calendar-item={true}
            disabled={
              (disabledBefore && startOfDay(day.date) < startOfDay(disabledBefore)) ||
              (disabledAfter && startOfDay(day.date) > startOfDay(disabledAfter))
            }
            autoFocus={isSelectedDate(day.date)}
            ref={(el: HTMLButtonElement) => (calendarRef.current[dayIdx] = el)}
            onKeyDown={(e) => handleKeyboardNavigation(e.currentTarget, e)}
            onClick={() => !!onSelect && onSelect(day.date)}
            className={twMerge(
              "group disabled:bg-gray-100 disabled:text-gray-300",
              day.isHoliday ? "bg-yellow-50" : "",
              highlightedDates?.length === 0 && "focus:bg-hover focus:text-white",
              day.isCurrentMonth ? "text-gray-900 " : "bg-gray-50 text-gray-400",
              isSelectedDate(day.date) && !highlightedDates?.length
                ? "bg-primary text-white hover:bg-hover"
                : "hover:bg-hover hover:text-white",
              isHighlightedDate(day.date) ? "bg-hover text-white" : "",
              dayIdx === 6 ? "rounded-tr-lg" : "",
              dayIdx === calendarDates.days.length - 1 ? "rounded-br-lg" : "",
              day.isToday ? "aspect-square p-1.5" : "aspect-square py-1.5 focus:z-10"
            )}
          >
            <time
              dateTime={day.date.toISOString()}
              className={twMerge(
                day.isToday &&
                  !isSelectedDate(day.date) &&
                  targetIndex !== day.calendarIndex &&
                  !isHighlightedDate(day.date)
                  ? "aspect-square bg-shade-500 font-semibold text-white group-focus:bg-hover"
                  : "",
                "mx-auto flex items-center justify-center rounded-full"
              )}
            >
              {day.shortDate}
            </time>
          </button>
        </Fragment>
      ))}
    </>
  );
}
