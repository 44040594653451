import { useTranslation } from "react-i18next";
import { ComboboxListItem } from "~/lib/ui/form-elements/types";
import { Dialog, Icon, Label } from "~/lib/ui";
import ProductRulesTooltip from "~/pages/products/_cmp/product-rules-tooltip";
import { SelectionCombobox } from "~/lib/ui/selection-combobox";

export function ProductRulesSelect({
  onChange,
  initialProductRule,
  hideLabel = false,
}: {
  onChange: (value: string | null) => void;
  initialProductRule?: string | null;
  hideLabel?: boolean;
}) {
  const { t } = useTranslation();

  const priceTypes: Array<ComboboxListItem> = [
    { id: "NONE", label: t("common:none"), value: "null" },
    {
      id: "REPLACE_WORKING_HOURS",
      label: t("products:replace_working_hours"),
      value: "REPLACE_WORKING_HOURS",
    },
    {
      id: "REDUCE_WORKING_HOURS",
      label: t("products:reduce_working_hours"),
      value: "REDUCE_WORKING_HOURS",
    },
  ];
  const defaultPriceType = priceTypes.find((i) => i.id === initialProductRule) || null;

  return (
    <div>
      {!hideLabel && (
        <div className="flex items-center gap-1">
          <Label>{t("products:product_rules")}</Label>
          <Dialog
            trigger={
              <div>
                <Icon name="tooltip" className="mb-1 h-5 w-5 cursor-pointer" />
              </div>
            }
            render={() => ProductRulesTooltip()}
          />
        </div>
      )}
      <SelectionCombobox<ComboboxListItem>
        required
        defaultValue={defaultPriceType?.value}
        onSelect={(e) => onChange?.(e === "null" ? null : e)}
        data={priceTypes}
        valueFn={(e) => e.value}
        labelFn={(e) => e.label}
      />
    </div>
  );
}
