import { useState } from "react";
import { Button, Dialog } from "~/lib/ui";

export default function ModalPlayground() {
  const [open, setOpen] = useState(false);
  const handleDialogSubmit = (value: boolean) => {
    console.log("answer received", value);
  };

  return (
    <section>
      <h2>Modal stuff</h2>

      <div>
        <h4>Trigger</h4>
        <Dialog
          trigger={<Button variant="primary">Halløj</Button>}
          render={({ onClose }) => <p>Halløj</p>}
        />
      </div>

      <div>
        <h4>Programmatic</h4>
        <Button variant="primary" onClick={() => setOpen(true)}>
          Open modal
        </Button>
        <Dialog
          open={open}
          onOpenChange={setOpen}
          render={({ onClose }) => (
            <div>
              <h3>Do you like it?</h3>
              <div className="flex gap-4">
                <Button variant="tertiary" onClick={() => handleDialogSubmit(true)}>
                  Yes
                </Button>
                <Button variant="primary" onClick={() => handleDialogSubmit(false)}>
                  No
                </Button>
                <Button variant="secondary" onClick={() => onClose()}>
                  Just close
                </Button>
              </div>
            </div>
          )}
        />
      </div>
    </section>
  );
}
