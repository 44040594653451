import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { DocumentArrowUpIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import DateInput from "~/lib/ui/calendar/date-input";
import { useToasts } from "~/lib/toast/use-toasts";
import { useAPI } from "~/lib/api";
import TextInput from "~/lib/ui/form-elements/text-input";
import { useSession } from "~/lib/auth/session";
import { toLocalDateString } from "~/lib/utils/date";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { ReportType } from "./report.types";

export function GenerateReportModal({
  onClose,
  report,
}: {
  onClose: () => void;
  report: ReportType;
}) {
  const { t } = useTranslation();
  const toast = useToasts();
  const reportName = t("reports:" + report.type);
  const api = useAPI();
  // Field inputs

  const [email, setEmail] = useState<string>();
  const [dateTo, setDateTo] = useState<Date>(new Date());
  const [dateFrom, setDateFrom] = useState<Date>();
  const { me } = useSession();

  const mutation = useMutation({
    mutationFn: () => {
      return api.sendReport({
        type: report.type,
        sendReportRequest: {
          email: email || me?.user.email,
          dateFrom: dateFrom ? toLocalDateString(dateFrom) : undefined,
          dateTo: toLocalDateString(dateTo),
        },
      });
    },
    onSuccess: () => {
      toast.showTemplate("CHANGES_SAVED", {
        title: t("reports:toast_success_title"),
        description: t("reports:toast_success_description"),
      });
      onClose();
    },
    onError: () => {
      toast.showTemplate("OPERATION_FAILED", {
        title: t("reports:toast_error_title"),
        description: t("reports:toast_error_description"),
      });
    },
  });
  async function handleSubmit() {
    await mutation.mutateAsync();
  }

  return (
    <>
      <DialogHeader title={reportName} Icon={DocumentArrowUpIcon} />
      <div className="mb-12">
        <div className="mt-3 text-center sm:mt-5">
          <div className="mt-2">
            {report.type === "ongoing_work" && (
              <div className="mb-8 flex flex-col gap-2 border bg-gray-100 p-2">
                <div className="flex items-center gap-4">
                  <div className="rounded-full bg-yellow-500 p-1.5 text-white">
                    <ExclamationTriangleIcon className="h-6 w-6" />
                  </div>
                  <div className="text-sm">{t("reports:ongoing_report_warning")}</div>
                </div>
              </div>
            )}
            <div className="flex flex-col gap-5">
              {!report?.fields.includes("date_from") && (
                <DateInput
                  label={t("reports:statement_date")}
                  value={dateTo}
                  onChange={setDateTo}
                  name="date_to"
                ></DateInput>
              )}
              {report?.fields.includes("date_to") && report?.fields.includes("date_from") && (
                <>
                  <DateInput
                    value={dateFrom}
                    onChange={setDateFrom}
                    label={t("common:from_date")}
                    name="date_from"
                  ></DateInput>
                  <DateInput
                    onChange={setDateTo}
                    value={dateTo}
                    label={t("common:to_date")}
                    name="date_to"
                  ></DateInput>
                </>
              )}
              <TextInput
                onChange={setEmail}
                defaultValue={me?.user.email}
                placeholder={me?.user.email}
                type="email"
                label={t("common:email")}
                name="email"
              ></TextInput>
            </div>
          </div>
        </div>
      </div>
      <DialogFooter
        onClose={onClose}
        primary={{
          onClick: handleSubmit,
          label: t("reports:send"),
        }}
      />
    </>
  );
}
