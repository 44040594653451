import { Contact, ICreateProjectRequest } from "@apacta/sdk";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import TextInput from "~/lib/ui/form-elements/text-input";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { useMemo, useState } from "react";
import { useDebounce } from "~/lib/debounce/use-debounce";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { StreetAddressInput } from "~/lib/ui/street-address-input";
import { ZipInput } from "~/lib/ui/zip-input";
import { CreateCustomerDialog } from "~/pages/customers/_cmp/create-customer-dialog";
import { CACHE_PROJECTS } from "..";
import { Dialog, getIcon } from "~/lib/ui";
import PriceSection from "./data-components/price-section";
import Switch from "~/lib/ui/switch";
import { LabeledTextarea } from "~/lib/ui/form-elements/labeled-textarea";
import { CustomerSelection } from "~/lib/ui/selection-combobox/customer-selection";

type CreateProjectDialogProps = {
  defaultName?: string;
  defaultDescription?: string;
  defaultCustomerId?: string;
  defaultWorkAddress?: string;
  defaultZipCode?: string;
  onProjectCreated?: (projectId: string) => void;
  onOpenChange?: (isOpen: boolean) => void;
};

export function CreateProjectDialog(props: CreateProjectDialogProps) {
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();
  const [contactSearchQuery, setContactSearchQuery] = useState<string>("");
  const [contacts, setContacts] = useState<Array<Contact>>([]);
  const [selectedContact, setSelectedContact] = useState<Contact | null | undefined>();
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState<boolean>(false);
  const [displayCreateContact, setDisplayCreateContact] = useState<boolean>(false);

  const { onChange, register, isValid, getValue, setValues } = useFormState({
    schema: {
      name: z.string().min(1),
      description: z.string(),
      customerId: z.string().uuid().nullable(),
      workAddress: z.string().optional(),
      zipCode: z.string().optional(),
      cityId: z.string().uuid().optional(),
      cityName: z.string().optional(),
      isFixedPrice: z.boolean(),
      totalSalesPrice: z.number(),
      workingHoursTotalCostPrice: z.number(),
      productsTotalCostPrice: z.number(),
    },
    initialValues: {
      name: props.defaultName || "",
      description: props.defaultDescription || "",
      customerId: props.defaultCustomerId || null,
      workAddress: props.defaultWorkAddress || undefined,
      zipCode: props.defaultZipCode || undefined,
      cityId: undefined,
      cityName: undefined,
      isFixedPrice: false,
      productsTotalCostPrice: 0,
      workingHoursTotalCostPrice: 0,
      totalSalesPrice: 0,
    },
  });

  const createM = useMutation({
    mutationFn: (args: ICreateProjectRequest) =>
      api.iCreateProject({ iCreateProjectRequest: args }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_PROJECTS], // this causes a refetch
      });
      if (data.data.id) {
        props.onProjectCreated?.(data.data.id);
      }
    },
  });

  function handleSubmit() {
    createM.mutate({
      name: getValue("name"),
      description: getValue("description"),
      contactId: getValue("customerId"),
      cityId: getValue("cityId"),
      cityName: getValue("cityName"),
      streetName: getValue("workAddress"),
      zipCode: getValue("zipCode"),
      isFixedPrice: getValue("isFixedPrice"),
      productsTotalCostPrice: getValue("productsTotalCostPrice"),
      totalSalesPrice: getValue("totalSalesPrice"),
      workingHoursTotalCostPrice: getValue("workingHoursTotalCostPrice"),
    });
  }

  async function handleCreateCustomer() {
    setIsCustomerModalOpen(true);
  }

  useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["contacts", contactSearchQuery],
    queryFn: () => {
      return api.getContacts({ q: contactSearchQuery }).then((d) => {
        setContacts(d.data);
        return d.data || [];
      });
    },
  });

  const handleSetSelectedContact = async (customerId: string | null) => {
    if (!customerId) {
      setSelectedContact(null);
    } else {
      onChange("customerId", customerId);
      await api.getContact({ contactId: customerId }, {}).then((res) => {
        setSelectedContact(res.data);
      });
    }
  };

  useDebounce(
    () => {
      if (props.defaultCustomerId) {
        handleSetSelectedContact(props.defaultCustomerId);
      }
    },
    [],
    1
  );

  const filteredContacts = useMemo<Array<Contact>>(() => {
    const fContacts = contacts.filter((c) => {
      if (contactSearchQuery === "") {
        return true;
      }
      return c.name.toLowerCase().includes(contactSearchQuery.toLowerCase().trim());
    });

    const dataContainsSelected =
      selectedContact && fContacts.filter((d) => d.id === selectedContact.id).length > 0;

    if (selectedContact && !dataContainsSelected) {
      fContacts.unshift(selectedContact);
    }

    // Do not display create entity in dropdown if we have an exact match
    const exactMatch =
      fContacts.filter((p) => p.name.toLowerCase() === contactSearchQuery.toLowerCase()).length > 0;

    const hasResults = !!fContacts.length;
    const hasSearchQuery = contactSearchQuery !== "";

    setDisplayCreateContact(!hasResults || (hasSearchQuery && hasResults && !exactMatch));

    const returnValue = fContacts;

    return returnValue;
  }, [contacts, contactSearchQuery, selectedContact]);

  const handleSelectContact = (customerId: string | null, customer: Contact | null) => {
    if (customerId === null || customer === null || customerId === selectedContact?.id) {
      onChange("customerId", null);
      setSelectedContact(undefined);
    } else {
      onChange("customerId", customerId);
      setSelectedContact(customer);
      setValues({
        cityId: customer.cityId ?? undefined,
        cityName: customer.cityName ?? undefined,
        workAddress: customer.address ?? undefined,
        zipCode: customer.zipCode ?? undefined,
      });
    }
  };

  function handleComboQuery(query: string) {
    if (isCustomerModalOpen) {
      return; // We do not want the search reset
    }
    setContactSearchQuery(query);
  }

  function handleOpenChange(isOpen: boolean) {
    setIsCustomerModalOpen(isOpen);
    props.onOpenChange?.(isOpen);
  }

  return (
    <>
      <DialogHeader
        title={t("common:create", {
          replace: { entity: t("common:project", { count: 1 }).toLocaleLowerCase() },
        })}
        Icon={getIcon("project")}
      />
      <div className="mb-8  flex h-full w-full flex-row gap-6">
        <div className="flex flex-1 flex-col gap-2">
          <TextInput {...register("name")} label={t("common:name")} />

          <LabeledTextarea
            label={t("common:description")}
            onChange={(e) => onChange("description", e.currentTarget.value)}
            className="h-fit"
          />
          <Switch
            label={t("projects:fixed_price")}
            defaultChecked={getValue("isFixedPrice")}
            onCheckedChange={(v) => onChange("isFixedPrice", v)}
          />
        </div>
        <div className="flex flex-1 flex-col gap-2">
          <CustomerSelection
            onSelect={handleSelectContact}
            value={selectedContact?.id}
            controlled
          />
          <StreetAddressInput
            value={getValue("workAddress")}
            label={t("projects:worksite_address")}
            onChange={(val) => onChange("workAddress", val)}
            onSelect={(selection) => {
              setValues({
                workAddress: selection.addressWithNumber,
                zipCode: selection.zipCode,
                cityName: selection.cityName,
                cityId: selection.cityId,
              });
            }}
          />
          <div className="flex flex-row gap-4">
            <ZipInput
              placeholder={t("common:zip_code")}
              value={getValue("zipCode")}
              label={t("common:zip_code", { defaultValue: "Zip code" })}
              disabled={true}
            />
            <TextInput
              disabled
              controlled
              value={getValue("cityName")}
              label={t("common:city_name")}
            />
          </div>
        </div>
      </div>
      <div id="project_price_settings" className="mb-4">
        <PriceSection
          isOffer={false}
          isFixedPrice={getValue("isFixedPrice")}
          onIsFixedPriceChange={(v) => onChange("isFixedPrice", v)}
          productsTotalCostPrice={getValue("productsTotalCostPrice")}
          onProductsTotalCostPriceChange={(v) => onChange("productsTotalCostPrice", v)}
          totalSalesPrice={getValue("totalSalesPrice")}
          onTotalSalesPriceChange={(v) => onChange("totalSalesPrice", v)}
          workingHoursTotalCostPrice={getValue("workingHoursTotalCostPrice")}
          onWorkingHoursTotalCostPriceChange={(v) => onChange("workingHoursTotalCostPrice", v)}
        />
      </div>
      <DialogFooter
        primary={{
          label: t("common:create", {
            replace: { entity: t("common:project", { count: 1 }).toLocaleLowerCase() },
          }),
          onClick: handleSubmit,
          disabled: !isValid || createM.isPending,
          loading: createM.isPending,
        }}
        onClose={() => handleOpenChange(false)}
      />
      <Dialog
        open={isCustomerModalOpen}
        onOpenChange={(isOpen) => setIsCustomerModalOpen(isOpen)}
        render={({ onClose }) => (
          <CreateCustomerDialog
            key={contactSearchQuery} // This forces a fresh formstate for the customer name
            onOpenChange={(isOpen) => setIsCustomerModalOpen(isOpen)}
            onCustomerCreated={handleSetSelectedContact}
            defaultCustomerName={contactSearchQuery}
          />
        )}
      />
    </>
  );
}
