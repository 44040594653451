import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import TextInput from "~/lib/ui/form-elements/text-input";
import { RichTextEditor, RichTextEditorRef } from "~/lib/ui/rich-text-editor";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import Switch from "~/lib/ui/switch";

export type CreateTemplateData = {
  name: string;
  content: string;
  availability: TemplateAvailability;
};

type TemplateAvailability = {
  offer: boolean;
  invoice: boolean;
  expense: boolean;
};

export default function CreateTemplateModal({
  onSubmit,
  onClose,
  isProcessing = false,
}: {
  onSubmit: ({ name, content }: CreateTemplateData) => void;
  onClose: () => void;
  isProcessing?: boolean;
}) {
  const { t } = useTranslation();
  const editorRef = useRef<RichTextEditorRef>(null);
  const [editorValid, setEditorValid] = useState<boolean>(false);
  const [availability, setAvailability] = useState<TemplateAvailability>({
    offer: false,
    invoice: false,
    expense: false,
  });

  const { getValue, register, isValid } = useFormState({
    schema: {
      name: z.string().nonempty(),
    },
    initialValues: {
      name: "",
    },
  });

  const handleSubmit = async () => {
    if (!editorRef.current) return; // display error toast

    onSubmit({
      name: getValue("name"),
      content: await editorRef.current.getEditorMarkdown(),
      availability,
    });
  };

  const handleAvailabilityChange = (key: keyof TemplateAvailability, value: boolean) => {
    setAvailability((prev) => ({ ...prev, [key]: value }));
  };

  const handleRTEChange = (val: string, valid: boolean) => {
    setEditorValid(val.length > 0 && valid);
  };

  return (
    <div>
      <DialogHeader
        title={t("common:create", { entity: t("common:template", { count: 1 }).toLowerCase() })}
        Icon={DocumentTextIcon}
      />
      <div className="mb-12">
        <div className="flex flex-col gap-8">
          <TextInput {...register("name")} label={t("settings:templates.table.name")} />
          <RichTextEditor
            ref={editorRef}
            label={`${t("common:template", { count: 1 })} *`}
            onChange={(text, html, valid) => handleRTEChange(text, valid)}
          />
          <div>
            <span className="mb-1 block text-left text-sm font-medium text-gray-700">
              {t("settings:templates.modal.availability")}
            </span>
            <div className="mt-4 flex gap-12">
              <div className="flex flex-col gap-2">
                <label>{t("common:enabled", { count: 1 })}</label>
                <Switch
                  defaultChecked={false}
                  onCheckedChange={(val) => handleAvailabilityChange("offer", val)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogFooter
        primary={{
          label: t("settings:templates.modal.create"),
          onClick: () => handleSubmit(),
          disabled: !editorValid || !isValid,
          loading: isProcessing,
        }}
        onClose={onClose}
      />
    </div>
  );
}
