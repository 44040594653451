import { useFormState } from "~/lib/form-state";
import { useTranslation } from "react-i18next";
import { Contact, ContactPerson, IGetProject200Response } from "@apacta/sdk";
import { useContacts } from "~/lib/ui/contacts/use-contacts";
import { ContactEntry } from "~/lib/ui/contact-entry";
import { linkToCustomer } from "~/lib/utils";
import { CustomerSelection } from "~/lib/ui/selection-combobox/customer-selection";
import { ContactPersonSelection } from "~/lib/ui/selection-combobox/contact-person-selection";
import { Icon } from "~/lib/ui";

export default function ProjectCustomerSelection({
  project,
  formState,
}: {
  project: IGetProject200Response["data"];
  formState: ReturnType<typeof useFormState>;
}) {
  // TODO: It's not ideal that we're fetching this twice.
  const { contact, contactPerson, setContact, setContactPerson } = useContacts(
    project.contact,
    project.contactPerson
  );

  const { t } = useTranslation();

  const handleSelectCustomer = (customerId: string | null, c: Contact) => {
    setContact(c);
    setContactPerson(null);
    formState.setValues({ contactId: customerId, contactPersonId: null });
  };

  const handleSelectCustomerContact = (contactPersonId: string | null, c: ContactPerson) => {
    setContactPerson(c);
    formState.setValues({ contactPersonId: contactPersonId });
  };

  return (
    <>
      <div>
        <div className="flex flex-col gap-4 bg-white">
          <CustomerSelection
            label={t("common:customer", { count: 1 })}
            onSelect={handleSelectCustomer}
            value={contact?.id}
            controlled
          />

          {contact && (
            <>
              <a
                href={linkToCustomer(contact?.id as string)}
                target="_blank"
                rel="noopener noreferrer"
                className="flex cursor-pointer items-center justify-end gap-2 self-end text-sm text-gray-700 hover:underline"
              >
                <span>{`${t("common:go_to")} ${t("common:customer", {
                  count: 1,
                }).toLowerCase()}`}</span>
                <Icon name="externalLink" className="h-4 w-4" />
              </a>
              <ContactEntry
                phone={contact.phone ?? undefined}
                email={contact.email ?? undefined}
                address={{
                  street: contact.address ?? "",
                  zipCode: contact.zipCode,
                  cityName: contact.cityName,
                }}
                inline={true}
              />
            </>
          )}
        </div>
      </div>

      <div className="mt-4">
        <div className="flex flex-col gap-4 bg-white">
          <ContactPersonSelection
            controlled
            label={t("common:customer_contact", { count: 1 })}
            customerId={contact?.id}
            value={contactPerson?.id}
            onSelect={handleSelectCustomerContact}
          />
          {contactPerson && (
            <ContactEntry email={contactPerson?.email} phone={contactPerson?.phone} />
          )}
        </div>
      </div>
    </>
  );
}
