import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

export default function ProductRulesTooltip() {
  const { t } = useTranslation();

  return (
    <>
      <DialogHeader
        title={t("products:tooltip.title", "Faktureringsregel")}
        Icon={QuestionMarkCircleIcon}
      />
      <div className="mb-12">
        <div className="mt-3 sm:mt-5">
          <div className="mt-2">
            <div className="flex flex-col gap-5">
              <>
                <div>
                  <h4>
                    {t(
                      "products:tooltip.product_reduce_heading",
                      "Produktet reducerer medarbejderens timer"
                    )}
                  </h4>
                  <p>
                    {t(
                      "products:tooltip.product_reduce_p1",
                      "Denne regel anvendes hvis medarbejderens salgspris på timer er indhold i produktets pris, eksempelvis maskintimer. På fakturaen reduceres medarbejderens timer med samme antal som der er registreret af produktet."
                    )}
                  </p>
                  <p>
                    {t(
                      "products:tooltip.product_reduce_p2",
                      "Det vil sige at medarbejderen registrerer følgende:"
                    )}
                  </p>
                  <ul className="list-inside list-disc">
                    <li>8 {t("common:working_hours", "arbejdstimer")}</li>
                    <li>
                      5 {t("products:hour_backhoe_with_driver", "timer rendegraver med fører")}
                    </li>
                  </ul>
                  <p className="mt-3">
                    {t(
                      "products:tooltip.product_reduce_p3",
                      "På regningen reduceres medarbejderens timer med samme antal som der er registreret af produktet, så faktura lyder på:"
                    )}
                  </p>
                  <ul className="list-inside list-disc">
                    <li>3 {t("common:working_hours", "arbejdstimer")}</li>
                    <li>
                      5 {t("products:hour_backhoe_with_driver", "timer rendegraver med fører")}
                    </li>
                  </ul>
                  <h4>
                    {t(
                      "products:tooltip.product_replace_heading",
                      "Produktet erstatter medarbejderens timer"
                    )}
                  </h4>
                  <p>
                    {t(
                      "products:tooltip.product_reduce_p4",
                      "Denne regel anvendes hvis alle medarbejderens timer skal erstattes af produktet. På fakturaen vises kun antallet af det tilføjede produkt, og ikke antallet af registrerede arbejdstimer."
                    )}
                  </p>
                  <p>
                    {t(
                      "products:tooltip.product_reduce_p5",
                      "Det vil sige at medarbejderen registrerer følgende:"
                    )}
                  </p>
                  <ul className="list-inside list-disc">
                    <li>8 {t("common:working_hours", "arbejdstimer")}</li>
                    <li>20 {t("products:meter_vertical_joint", "meter lodret fuge")}</li>
                  </ul>
                  <p className="mt-3">
                    {t(
                      "products:tooltip.product_reduce_p6",
                      "På fakturaen erstattes medarbejderens timer med det registrerede produkt, så fakturaen lyder på:"
                    )}
                  </p>
                  <ul className="list-inside list-disc">
                    <li>20 {t("products:meter_vertical_joint", "meter lodret fuge")}</li>
                  </ul>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
