import { ComponentProps, forwardRef, useState } from "react";
import { IconProp } from "./types";
import * as Popover from "@radix-ui/react-popover";
import { twMerge } from "tailwind-merge";
import { Button } from "./buttons/button";
import { ButtonSizes } from "./buttons/shared/button.shared";
import { getIcon } from "./icons/icon";
import { ConfirmationDialog, Dialog } from "./dialog";
import { Link } from "react-router-dom";

export type ButtonAction = {
  label: string;
  Icon?: IconProp;
  isHidden?: boolean;
  /** Promises are automatically handled, use this if you want to manually control loading state */
  isLoading?: boolean;
  mustConfirm?: boolean;
  disabled?: boolean;
  collapseBehavior?: "default" | "always" | "never";
  onClick?: () => void | Promise<unknown>;
  confirmOptions?: Partial<ComponentProps<typeof ConfirmationDialog>>;
  href?: string;
  target?: string;
};

export function ActionButtons({
  actions,
  collapseAt = 3,
  buttonClassName,
  dropdownClassName,
  size,
  showLabels,
}: {
  actions: Array<ButtonAction>;
  collapseAt?: number;
  buttonClassName?: string;
  dropdownClassName?: string;
  size?: ButtonSizes;
  /** Affects when not collapsed */
  showLabels?: boolean;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<ButtonAction>();
  const [confirmOptions, setConfirmOptions] = useState<ButtonAction["confirmOptions"]>();

  const shownActions = actions.filter((action) => !action.isHidden);

  // Show all buttons with collapse=never, and the remaining buttons up to collapseAt
  const buttonActions: Array<ButtonAction> = [];
  const dropdownActions: Array<ButtonAction> = [];
  for (const a of shownActions) {
    if (a.collapseBehavior === "never") {
      buttonActions.push(a);
      continue;
    }
    if (a.collapseBehavior === "always") {
      dropdownActions.push(a);
      continue;
    }
    if (buttonActions.length < collapseAt) {
      buttonActions.push(a);
      continue;
    }
    dropdownActions.push(a);
  }

  const baseClass = twMerge(
    "relative inline-flex items-center border border-gray-300 bg-white bg-white px-4 py-2 text-sm font-medium text-gray-700 text-gray-700 hover:border-hover hover:text-hover focus:border-indigo-500 focus-visible:outline-hover",
    "hover:border-text-gray-400 disabled:text-gray-400"
  );

  async function handleActionClick(a: ButtonAction) {
    setOpen(false);

    // If we need a confirmation, the confirmation dialog will handle the action instead
    if (!!a.mustConfirm) {
      if (a.confirmOptions) setConfirmOptions(a.confirmOptions);
      setConfirmOpen(a);
      return;
    }

    return a.onClick?.();
  }

  async function handleConfirmAction(isConfirmed?: boolean) {
    isConfirmed ? await confirmOpen?.onClick?.() : undefined;
    setConfirmOpen(undefined);
  }

  const showButtons = buttonActions.length > 0;
  const showDropdown = dropdownActions.length > 0;

  return (
    <>
      <span
        className={twMerge("isolate hidden rounded-md shadow-sm", showButtons && "lg:inline-flex")}
      >
        {buttonActions.map((aprops, idx) => (
          <ActionButtonRender
            key={idx}
            button={aprops}
            size={size}
            className={twMerge(
              baseClass,
              idx === 0 && "rounded-l-md",
              idx === buttonActions.length - 1 && "rounded-r-md",
              buttonClassName
            )}
            onClick={(e) => handleActionClick(aprops)}
          />
        ))}
      </span>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger
          className={twMerge("-ml-px inline-flex", !showDropdown && "hidden")}
          asChild
        >
          <Button
            Icon={getIcon("menuKebab")}
            variant="secondary"
            size={size}
            className={dropdownClassName}
          />
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content align="end" sideOffset={10}>
            <div className="min-w-[10rem] rounded-lg bg-white p-2 shadow">
              {dropdownActions.map((a, idx) => (
                <ActionButtonRender
                  key={`item-${idx}`}
                  isDropdownItem={true}
                  button={a}
                  className="flex w-full cursor-pointer gap-2 rounded-lg p-2 hover:bg-shade-100"
                  onClick={(e) => handleActionClick(a)}
                />
              ))}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>

      <Dialog
        open={!!confirmOpen}
        onOpenChange={(isOpen) => setConfirmOpen(undefined)} // Close
        render={({ onClose }) => (
          <ConfirmationDialog
            onClose={onClose}
            onSubmit={handleConfirmAction}
            title={confirmOpen?.label}
            Icon={confirmOpen?.Icon}
            {...confirmOptions}
          />
        )}
      />
    </>
  );
}

type ActionButtonProps = {
  button: ButtonAction;
  size?: ButtonSizes;
  className: string;
  onClick?: (v: ButtonAction) => void;
  showLabels?: boolean;
};

const ActionButtonRender = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ActionButtonProps & { isDropdownItem?: boolean }
>((props, ref) => {
  if (!!props.button.href) {
    return (
      <Link to={props.button.href} target={props.button.target}>
        {props.isDropdownItem ? (
          <DropdownActionButton
            button={props.button}
            size={props.size}
            className={props.className}
            showLabels={props.showLabels}
          />
        ) : (
          <ActionButton
            button={props.button}
            size={props.size}
            className={props.className}
            showLabels={props.showLabels}
          />
        )}
      </Link>
    );
  }

  return props.isDropdownItem ? (
    <DropdownActionButton
      button={props.button}
      size={props.size}
      className={props.className}
      onClick={props.onClick}
      showLabels={props.showLabels}
    />
  ) : (
    <ActionButton
      button={props.button}
      size={props.size}
      className={props.className}
      onClick={props.onClick}
      showLabels={props.showLabels}
    />
  );
});

ActionButtonRender.displayName = "ActionButtonRender";

const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>((props, ref) => {
  const { button, size, className, onClick, showLabels } = props;
  return (
    <Button
      title={button.label}
      size={size}
      aria-label={button.label}
      disabled={button.disabled}
      onClick={(e) => onClick?.(button)}
      loading={button.isLoading}
      Icon={button.Icon}
      iconClassName="w-5 h-5"
      className={className}
    >
      {showLabels ? button.label : null}
    </Button>
  );
});

ActionButton.displayName = "ActionButton";

const DropdownActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>((props, ref) => {
  const { button, size, className, onClick, showLabels } = props;
  return (
    <Button
      size={size}
      Icon={button.Icon}
      loading={button.isLoading}
      disabled={button.disabled}
      className={className}
      onClick={(e) => onClick?.(button)}
    >
      {button.label}
    </Button>
  );
});

DropdownActionButton.displayName = "DropdownActionButton";
