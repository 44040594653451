import {
  CalendarSection,
  DescriptionSection,
  EmployeeSection,
  LabelSection,
  TaskNameSection,
} from "~/pages/planning/_cmp/crud/sections";
import { Suspense } from "react";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { TaskFormState } from "~/pages/planning/_cmp/types";
import { StatusSection } from "~/pages/planning/_cmp/crud/sections/status-section";

export function DetailsTab({
  formState,
  selectedEmployee,
  repeatInfo,
}: {
  formState: TaskFormState;
  selectedEmployee?: string | null;
  repeatInfo?: { isInfiniteRepeat?: boolean; endTime?: Date };
}) {
  return (
    <div className="flex flex-col gap-2">
      <TaskNameSection
        formState={formState}
        defaultTaskName={formState.getValue("taskName") ?? ""}
      />
      <CalendarSection repeatInfo={repeatInfo} formState={formState} />
      <Suspense fallback={<SkeletonLoader template="box" className="h-10 px-2" />}>
        <EmployeeSection
          defaultEmployeeIds={selectedEmployee ? [selectedEmployee] : []}
          formState={formState}
        />
      </Suspense>
      <LabelSection formState={formState} />
      <StatusSection formState={formState} />
      <DescriptionSection formState={formState} />
    </div>
  );
}
