import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { Country } from "@apacta/sdk";
import { Label } from "~/lib/ui/label";
import { Input } from "~/lib/ui/form-elements";
import { Icon } from "~/lib/ui/icons/icon";
import { Dropdown } from "~/lib/ui/dropdown";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import { useTranslation } from "react-i18next";

type MobileCountryCodeType = {
  id: string;
  identifier: string;
  flag: string;
  label: string;
  value: string;
};

type Phone = {
  mobile: string;
  code: string;
};

/**
 * The PhoneInput component is a custom React component designed to provide a phone input field with a dropdown list of country codes.
 * It allows users to select their country code and enter their mobile phone number.
 *
 * @param onChange A required callback function that will be called when the phone input value changes.
 * It receives an object with the mobile and code properties representing the mobile phone number and the selected country code, respectively.
 * @param initialCountryCodeId An optional initial selected country code.
 * @param initialMobile An optional initial value of the mobile phone input field.
 * @constructor
 */
export function PhoneInput({
  onChange,
  initialCountryCodeId = "45",
  initialMobile,
}: {
  onChange: (phone: Phone) => void;
  initialCountryCodeId?: string;
  initialMobile?: string;
}) {
  const [mobile, setMobile] = useState<string>(initialMobile ?? "");
  const { t } = useTranslation();

  const api = useAPI();
  const { data } = useQuery({
    queryKey: ["countries"],
    queryFn: () => api.getCountries(),
  });
  const countryCodes = data?.data.map((country) => countryToSelectOption(country)) || [];

  const defaultSelectedCode =
    countryCodes.find((c) => c.value === initialCountryCodeId) ?? countryCodes[0];
  const [selectedCode, setSelectedCode] = useState<MobileCountryCodeType | null>(
    defaultSelectedCode
  );

  useEffect(() => {
    if (defaultSelectedCode !== undefined && selectedCode === undefined) {
      setSelectedCode(defaultSelectedCode);
    }
  }, [defaultSelectedCode]);

  function countryToSelectOption(country: Country): MobileCountryCodeType {
    return {
      id: country.phoneCode || "",
      identifier: country.identifier,
      flag: `/flags/${country.identifier.toLowerCase()}.png`,
      label: country.name,
      value: country.phoneCode?.substring(1) || "",
    };
  }

  useEffect(() => {
    onChange?.({ mobile: mobile, code: selectedCode?.value ?? "" });
  }, [mobile, selectedCode]);

  return (
    <div className="w-full">
      <Label>{t("customers:modal.create_customer.input.phone")}</Label>
      <div className="relative">
        <div className="absolute flex h-full items-center border-r px-2">
          <Dropdown
            options={{
              align: { side: "bottom", align: "start" },
            }}
            trigger={
              <span className="flex h-full items-center gap-2 text-sm">
                <img
                  src={selectedCode?.flag}
                  alt=""
                  className="h-5 w-5 flex-shrink-0 rounded-full"
                />
                <span className="pt-0.5">{`+${selectedCode?.value}`}</span>
                <Icon name="chevronDown" className="h-4 w-4" />
              </span>
            }
          >
            <ScrollArea.Root className="h-48 w-62 overflow-hidden rounded bg-white shadow-md">
              <ScrollArea.Viewport className="h-full w-full rounded">
                {countryCodes.map((code) => (
                  <Dropdown.Item key={code.id} onSelect={() => setSelectedCode(code)}>
                    <div className="flex items-center gap-2 text-sm">
                      <img src={code?.flag} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" />
                      <span className="pt-0.5">{`+${code?.value}`}</span>
                      <span>{code.label}</span>
                    </div>
                  </Dropdown.Item>
                ))}
              </ScrollArea.Viewport>
              <ScrollArea.Scrollbar
                orientation="vertical"
                className="duration-[160ms] flex touch-none select-none bg-transparent p-0.5 transition-colors ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
              >
                <ScrollArea.Thumb className="relative flex-1 rounded-sm bg-black/30 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-[''] hover:bg-black/50" />
              </ScrollArea.Scrollbar>
            </ScrollArea.Root>
          </Dropdown>
        </div>
        <Input defaultValue={mobile} className="pl-28" />
      </div>
    </div>
  );
}
