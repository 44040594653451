import {
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import { Button, Icon, Label } from "..";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../command/command";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { SelectionComboboxProps, TriggerComponentProps } from "./combobox-types";
import { DefaultSingleSelectTrigger } from "./defaults/DefaultSingleSelectTrigger";
import { DefaultMultiSelectTrigger } from "./defaults/DefaultMultiSelectTrigger";
import { DefaultCreateComponent } from "./defaults/DefaultCreateComponent";
import { DefaultEmptyComponent } from "./defaults/DefaultEmptyComponent";

export function SelectionCombobox<D>(props: SelectionComboboxProps<D>) {
  const { t } = useTranslation();
  const queryChangeTimer = useRef<ReturnType<typeof setTimeout> | undefined>();

  // Defaults
  const {
    placeholder = t("common:pick_or_search", "Pick or search..."),
    keyFn = props.valueFn,
    EmptyComponent = DefaultEmptyComponent,
    side = "bottom",
    required,
  } = props;

  const [open, setOpen] = useState(false);
  const selectionDefault = (() => {
    if (props.defaultValue === undefined) return [];
    if (Array.isArray(props.defaultValue)) return props.defaultValue;
    return [props.defaultValue];
  })();

  const [internalSelection, setInternalSelection] = useState(selectionDefault);
  // This is passed to Triggers and updated when the user confirms the selection
  const [renderedSelection, setRenderedSelection] = useState(selectionDefault);

  const values = (() => {
    if (props.value === undefined) return internalSelection;
    if (Array.isArray(props.value)) return props.value;
    return [props.value];
  })() satisfies Array<string>;

  if (values.length === 0 && props.readonly) {
    console.warn("SelectionCombobox is readonly, but no value is provided", props);
  }

  const data = props.filterFn ? props.data.filter((d) => props.filterFn?.(d)) : props.data;

  const selectedItems = data.filter((d) => {
    if (values.includes(props.valueFn(d))) return true;
    return false;
  });

  const selectedConfirmedItems = data.filter((d) => {
    if (renderedSelection.includes(props.valueFn(d))) return true;
    return false;
  });

  // Single selection label

  const handleValueChange = useCallback(
    (currentValue: string) => {
      const selectedItem = data.find((d) => props.valueFn(d) === currentValue);
      if (!selectedItem) {
        console.warn("Item selected, but no item found in data", currentValue, data);
        return;
      }
      // Single select
      if (!props.multiple) {
        // Allow deselecting (clear)
        if (internalSelection.includes(currentValue) && !required) {
          setInternalSelection([]);
          setRenderedSelection([]);
          props.onSelect?.(null, selectedItem);
          setOpen(false);
        } else {
          setInternalSelection([currentValue]); // uncontrolled
          setRenderedSelection([currentValue]);
          props.onSelect?.(currentValue, selectedItem);
          setOpen(false);
        }
      }

      // Multi select (controlled  + uncontrolled)
      if (props.multiple) {
        // If already selected, remove from selection
        if (values.includes(currentValue)) {
          // Remove existing
          setInternalSelection(() => {
            const newSelection = values.filter((v) => v !== currentValue);
            if (props.controlled) {
              props.onSelect(
                newSelection,
                data.filter((d) => newSelection.includes(props.valueFn(d)))
              );
            }
            return newSelection;
          });
        } else {
          // Add new
          setInternalSelection(() => {
            const newSelection = [...values, currentValue];
            if (props.controlled) {
              props.onSelect(
                newSelection,
                data.filter((d) => newSelection.includes(props.valueFn(d)))
              );
            }
            return newSelection;
          });
        }

        // If there is only one value to select, just close it
        if (data.length === 1) {
          setOpen(false);
        }
      }
    },
    [setInternalSelection, values, data]
  );

  // Register a value change handler with parent, if available.
  // This allows the parent to trigger a value change (e.g. creating items)
  useEffect(() => {
    if (props.valueChangeRef === undefined) return;
    props.valueChangeRef.current = handleValueChange;
    // Dependencies need to match that of handleValueChange + valueChangeRef
  }, [props.valueChangeRef, handleValueChange, values, data, setInternalSelection]);

  function handleQueryChange(query: string) {
    clearTimeout(queryChangeTimer.current);
    queryChangeTimer.current = setTimeout(() => {
      props.onQueryChange?.(query);
    }, 500);
  }

  function handleOpenChange(nextOpen: boolean) {
    setOpen(nextOpen);
    if (nextOpen === false && props.multiple) {
      // When closing multi-select, we'd expect it to remember the selection
      props.onSelect(values, selectedItems);
    }
    if (nextOpen === false) {
      // This automatically resets the query when the user closes the dialog
      props.onQueryChange?.("");
    }
    props.onOpenChange?.(nextOpen);
  }

  function handleConfirmMultiSelect() {
    if (props.multiple) {
      const items = data.filter((d) => values.includes(props.valueFn(d)));
      setRenderedSelection(values);
      props.onSelect(values, items);
    }
    setOpen(false);
  }

  // Handler for removing items in multi-select
  function handleRemoveItem(value: string) {
    if (!props.multiple) return; // Only supported for multi-select
    const newSelection = values.filter((v) => v !== value);
    setInternalSelection(newSelection);
    props.onSelect?.(
      newSelection,
      data.filter((d) => newSelection.includes(props.valueFn(d)))
    );
    setRenderedSelection(newSelection);
  }

  const isSelected = useCallback(
    (item: D) => {
      return values.includes(props.valueFn(item));
    },
    [values]
  );

  const triggerProps: TriggerComponentProps = {
    open,
    loading: props.loading,
    icon: props.icon,
    // Controlled needs the actual values, uncontrolled needs the rendered values
    selectedItems: props.controlled ? selectedItems : selectedConfirmedItems,
    readonly: props.readonly,
    onRemoveItem: handleRemoveItem,
    // This is typecasted because the TriggerComponent cannot know the underlying data type
    labelFn: props.labelFn as TriggerComponentProps["labelFn"],
    valueFn: props.valueFn as TriggerComponentProps["valueFn"],
    placeholder,
  };

  // Why did I add this memo again?
  const TriggerComponent = useMemo(() => {
    if (props.TriggerComponent) return props.TriggerComponent;
    if (props.multiple) return DefaultMultiSelectTrigger;
    return DefaultSingleSelectTrigger;
  }, []);

  return (
    <>
      {props.label && <Label required={props.required}>{props.label}</Label>}

      <Popover open={open} onOpenChange={handleOpenChange}>
        <PopoverAnchor>
          <div className="static" /> {/* To cover the Trigger and be sticky */}
        </PopoverAnchor>
        <PopoverTrigger asChild disabled={props.disabled || props.readonly}>
          <TriggerComponent {...triggerProps} />
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent
            sideOffset={0}
            side={side}
            className=""
            style={{
              width: "var(--radix-popover-trigger-width)",
              minWidth: "20rem",
            }}
          >
            <div>
              <Command
                filter={(value, search, keywords) => {
                  if (keywords?.includes("{{create-item}}")) {
                    return 1;
                  }
                  if (value.toLowerCase().includes(search.toLowerCase())) return 1;
                  if (keywords?.some((k) => k.toLowerCase().includes(search.toLowerCase()))) {
                    return 1;
                  }
                  return 0;
                }}
              >
                <CommandInput
                  placeholder={props.searchPlaceholder ?? t("common:pick_or_search")}
                  disabled={props.disabled}
                  onValueChange={handleQueryChange}
                />

                <CommandList>
                  <CommandEmpty>
                    {/* This is somehow causing "Argument 1 is not an object" errors */}
                    {props.onCreateNew ? (
                      <DefaultCreateComponent onCreate={props.onCreateNew} />
                    ) : (
                      <EmptyComponent />
                    )}
                  </CommandEmpty>

                  <CommandGroup className="planning-scrollbar max-h-48 overflow-y-auto">
                    {data.map((d) => (
                      <CommandItem
                        keywords={[props.labelFn(d)]}
                        key={keyFn(d)}
                        value={props.valueFn(d)}
                        onSelect={handleValueChange}
                      >
                        <Icon
                          name="selectedCheck"
                          className={twMerge(
                            "mr-2 h-4 w-4",
                            isSelected(d) ? "opacity-100" : "opacity-0"
                          )}
                        />
                        {props.renderItem ? props.renderItem(d) : props.labelFn(d)}
                      </CommandItem>
                    ))}
                    {props.onCreateNew && <DefaultCreateComponent onCreate={props.onCreateNew} />}
                  </CommandGroup>
                </CommandList>
                {props.multiple && (
                  <div className="flex justify-end border-t border-t-shade-300">
                    <Button
                      onClick={handleConfirmMultiSelect}
                      onKeyDown={(e) => {
                        e.preventDefault();
                        if (e.key === "Enter") handleConfirmMultiSelect();
                      }}
                      className="font-bold focus:underline focus:ring-0 focus:ring-offset-0 "
                    >
                      {t("common:confirm")}
                    </Button>
                  </div>
                )}
              </Command>
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </>
  );
}

/**
 * Used for server-side searching. Returns undefined is searchQuery is defined...
 * Value if defined, defaultValue if defined... or undefined
 */
export function getSelectedIds(props: {
  value?: Array<string> | string;
  defaultValue?: Array<string> | string;
  isSearchDialogOpen?: boolean;
}): Array<string> | undefined {
  if (props.isSearchDialogOpen) return undefined;

  if (props.value && Array.isArray(props.value)) {
    if (props.value.length === 0) return undefined;
    return props.value;
  }
  if (props.value) return [props.value];
  if (props.defaultValue && Array.isArray(props.defaultValue)) {
    if (props.defaultValue.length === 0) return undefined;
    return props.defaultValue;
  }
  if (props.defaultValue) return [props.defaultValue];
  return undefined;
}
