import { PageLayout } from "~/lib/ui/page-layout";
import { createColumnHelper } from "@tanstack/react-table";
import { Form } from "@apacta/sdk";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { formatDate, toLocalDateString } from "~/lib/utils/date";
import { i18n } from "~/lib/i18n/i18n";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { useActivities } from "~/lib/activities/use-activities";
import Switch from "~/lib/ui/switch";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { getIcon } from "~/lib/ui";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import { useState } from "react";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { getOffsetDate } from "~/lib/utils/date/date-utils";
import { DataFilter } from "~/lib/ui/data-table/data-filter";
import {
  APPROVED_FILTER,
  FORM_TEMPLATE_FILTER,
  PROJECT_FILTER,
  SINGLE_EMPLOYEE_FILTER,
} from "~/lib/ui/data-table/filters";
import { DateRangePopover } from "~/lib/ui/calendar/date-range-popover";

type RegistrationFilterTypes = {
  approved?: boolean;
  dateFrom?: Date;
  dateTo?: Date;
  projectId?: Array<string>;
  employeeId?: string;
  formTemplateId?: Array<string>;
};

const columnHelper = createColumnHelper<Form>();

export default function RegistrationsPage() {
  const [pdfPreviewHref, setPdfPreviewHref] = useState<string | undefined>();

  const [searchParams, setSearchParams] = useTypedSearchParams<RegistrationFilterTypes>();

  const { t } = useTranslation();
  const api = useAPI();

  const { statuses } = useActivities();

  const getActivity = (id?: string): string | null => {
    if (!id) return null;
    return statuses.find((a) => a.id === id)?.name ?? null;
  };

  const handleApprovalToggle = async (id: string, projectId: string, currentState: boolean) => {
    await api.changeStatus({
      projectId: projectId,
      changeStatusRequest: {
        approve: !currentState,
        forms: [id],
      },
    });
    await dataQuery.refetch();
  };

  async function handleDeleteForm(id: string) {
    await api.deleteForm({ formId: id });
    await dataQuery.refetch();
  }

  const columns = useDataColumns(() => [
    columnHelper.accessor("formDate", {
      header: t("common:date"),
      id: "form_date",
      enableSorting: true,
      cell: ({ getValue, row }) => {
        return <div>{formatDate(getValue(), i18n.resolvedLanguage, { shortDate: true })}</div>;
      },
    }),
    columnHelper.accessor("user.fullName", {
      header: t("common:employee", { count: 1 }),
      id: "user.fullName",
      cell: ({ getValue }) => <div>{getValue()}</div>,
    }),
    columnHelper.accessor("project.fullName", {
      header: t("common:project", { count: 1 }),
      id: "project.fullName",
      cell: ({ getValue }) => <div>{getValue()}</div>,
    }),
    columnHelper.accessor("formTemplate.name", {
      header: t("planning:form", { count: 1 }),
      id: "formTemplate.name",
      cell: ({ getValue }) => <div>{getValue()}</div>,
    }),
    columnHelper.accessor("activityId", {
      header: t("common:activity", { count: 1 }),
      id: "activityId",
      cell: ({ getValue }) => <div>{getActivity(getValue())}</div>,
    }),
    columnHelper.accessor("approved", {
      header: t("common:approved"),
      id: "approved",
      cell: ({ getValue, row }) => (
        <div>
          <Switch
            key={row.original.id} // This is important to prevent reusing the same component if filter is applied
            defaultChecked={!!getValue()}
            onCheckedChange={() =>
              handleApprovalToggle(row.original.id, row.original.projectId, !!getValue())
            }
          />
        </div>
      ),
    }),
    columnHelper.display({
      id: "actions",
      meta: {
        className: "text-right",
      },
      cell: ({ row }) => (
        <ActionButtons
          actions={[
            {
              Icon: getIcon("preview"),
              label: t("common:preview"),
              mustConfirm: false,
              onClick: () => setPdfPreviewHref(row.original.linkToPdf),
            },
            {
              Icon: getIcon("edit"),
              label: t("common:edit"),
              mustConfirm: false,
              href: `/projects/${row.original.projectId}/registrations/${row.original.id}`,
              target: "_blank",
            },
            {
              Icon: getIcon("delete"),
              label: t("forms:modal.delete.title"),
              mustConfirm: true,
              confirmOptions: {
                description: t("forms:modal.delete.description"),
                variant: "alert",
              },
              onClick: () => handleDeleteForm(row.original.id),
            },
          ]}
        />
      ),
    }),
  ]);

  const tableState = useDataTableState();

  const dataQuery = useQuery({
    queryKey: [
      "registrations",
      searchParams,
      tableState.state.pagination,
      tableState.state.sorting,
    ],
    refetchOnWindowFocus: true,
    queryFn: async () =>
      api.formsList({
        approved: searchParams.approved,
        dateFrom: searchParams.dateFrom ? new Date(searchParams.dateFrom) : undefined,
        dateTo: searchParams.dateTo ? new Date(searchParams.dateTo) : undefined,
        projectId: searchParams.projectId ?? undefined,
        userId: searchParams.employeeId,
        formTemplateId: searchParams.formTemplateId ?? undefined,
        page: tableState.pageNumber,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection,
      }),
  });

  const table = useDataTable({
    columns,
    tableState,
    skeletonRows: 10,
    data: dataQuery.data?.data,
    isLoading: dataQuery.isLoading,
    backendPagination: dataQuery.data?.pagination,
  });

  const handlePeriodChange = (from?: Date, to?: Date) => {
    setSearchParams("dateFrom", from ? toLocalDateString(from) : undefined);
    setSearchParams("dateTo", to ? toLocalDateString(to) : undefined);
  };

  const defaultFromDate = getOffsetDate(new Date(), -14);
  const defaultToDate = new Date();

  return (
    <PageLayout
      title={t("common:registration", { count: 2 })}
      renderDescription={() => (
        <DateRangePopover
          dateFrom={searchParams.dateFrom ? new Date(searchParams.dateFrom) : defaultFromDate}
          dateTo={searchParams.dateTo ? new Date(searchParams.dateTo) : defaultToDate}
          onChange={(range) => handlePeriodChange(range.from, range.to)}
          resetLabel={t("common:reset")}
          onReset={() => handlePeriodChange(defaultFromDate, defaultToDate)}
        />
      )}
    >
      <FullScreenFilePreview
        open={!!pdfPreviewHref}
        fileUrl={pdfPreviewHref}
        onClose={() => setPdfPreviewHref(undefined)}
      />
      <DataTable
        table={table}
        renderFilters={() => (
          <DataFilter
            filters={[
              APPROVED_FILTER,
              PROJECT_FILTER,
              SINGLE_EMPLOYEE_FILTER,
              FORM_TEMPLATE_FILTER,
            ]}
          />
        )}
      />
    </PageLayout>
  );
}
