import { useTranslation } from "react-i18next";
import { getIcon } from "~/lib/ui";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";

export function MarkInvoicedDialog({
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  onSubmit: () => void | Promise<void>;
}) {
  const { t } = useTranslation();

  async function handleSubmit() {
    await onSubmit();
    onClose();
  }

  return (
    <>
      <DialogHeader
        title={t("projects:mark_as_invoiced", "Mark as Invoiced")}
        Icon={getIcon("invoice")}
      />
      <div className="mb-4">
        {t(
          "projects:mark_as_invoiced_description",
          "You are about to mark all products and working hours in the selected date range as invoiced. Are you sure you want to continue?"
        )}
      </div>

      <DialogFooter
        onClose={onClose}
        primary={{ label: t("projects:mark_as_invoiced"), onClick: handleSubmit }}
      />
    </>
  );
}
