import { ControlPanelApiPaymentTerm } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { useToastOnError } from "~/lib/utils/hooks";
import { SelectionCombobox } from "~/lib/ui/selection-combobox";

export function PaymentConditionSelection({
  onChange,
  initialConditionId,
  hideLabel = false,
}: {
  onChange: (condition: ControlPanelApiPaymentTerm | null) => void;
  initialConditionId?: string;
  hideLabel?: boolean;
}) {
  const api = useAPI();
  const { t } = useTranslation();

  const { data, error } = useSuspenseQuery({
    queryKey: ["conditions"],
    queryFn: () => api.getPaymentTerms(),
  });

  useToastOnError(error);

  const conditions = data?.data ?? [];

  const handleSelect = (conditionId: string | null) => {
    if (!conditionId) {
      onChange?.(null);
      return;
    }

    const selectedCondition = conditions.find((c) => c.id === conditionId);
    if (selectedCondition) {
      onChange?.(selectedCondition);
    }
  };

  return (
    <SelectionCombobox
      required
      label={hideLabel ? "" : t("customers:modal.create_customer.input.conditions")}
      defaultValue={initialConditionId}
      onSelect={handleSelect}
      data={conditions}
      valueFn={(e) => e.id ?? ""}
      labelFn={(e) => e.name ?? ""}
    />
  );
}
