import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { Card } from "@apacta/sdk";
import { getIcon, Icon } from "~/lib/ui";
import { usePlanning } from "~/lib/planning";
import { Card as PlanningCard } from "~/lib/planning/types";

export type DeleteOperation = "all" | "single" | "employee";
type DeleteOption = {
  operation: DeleteOperation;
  label: string;
  description: string;
  count: number;
};

export type DeleteDialogProps = {
  card?: PlanningCard;
  employeeCards: Array<Card>;
  allCards: Array<Card>;
  onSubmit: (card?: PlanningCard, operation?: DeleteOperation) => void;
  onClose: () => void;
};

export default function DeleteTaskDialog({
  card,
  employeeCards,
  allCards,
  onSubmit,
  onClose,
}: DeleteDialogProps) {
  const { t } = useTranslation();
  const { cardGetTask } = usePlanning();

  const hasRecurrence = allCards.some((c) => {
    const task = cardGetTask({ card: c as PlanningCard });
    return !!task?.repeat;
  });

  const options: Array<DeleteOption> = [
    {
      operation: "single",
      label: t("planning:delete_modal.options.single.title"),
      description: t("planning:delete_modal.options.single.description"),
      count: 1,
    },
    {
      operation: "employee",
      label: `${t("planning:delete_modal.options.employee.title")} (${hasRecurrence ? "*" : employeeCards.length})`,
      description: t("planning:delete_modal.options.employee.description"),
      count: employeeCards.length,
    },
    {
      operation: "all",
      label: `${t("planning:delete_modal.options.all.title")} (${hasRecurrence ? "*" : allCards.length})`,
      description: t("planning:delete_modal.options.all.description"),
      count: allCards.length,
    },
  ];

  const [selectedOption, setSelectedOption] = useState<DeleteOption>(options[0]);

  return (
    <div>
      <DialogHeader title={t("planning:delete_modal.title")} Icon={getIcon("delete")} />

      <div className="mb-6 px-2">
        <fieldset>
          <legend className="sr-only">{t("common:delete")}</legend>
          <div className="space-y-5">
            {options.map((option) => (
              <div key={`delete-option-${option.operation}`} className="flex items-center">
                <div className="flex h-7 items-center">
                  <input
                    id={option.operation}
                    aria-labelledby={`${option.operation}-label`}
                    aria-describedby={`${option.operation}-description`}
                    name="option"
                    type="radio"
                    onChange={() => setSelectedOption(option)}
                    defaultChecked={option.operation === "single"}
                    className="border-1.5 h-6 w-6 appearance-none border-zinc-700 text-hover focus:ring-transparent"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor={option.operation}
                    id={`${option.operation}-label`}
                    className="font-medium text-gray-900"
                  >
                    {option.label}
                    <p id={`${option.operation}-description`} className="text-xs text-gray-500">
                      {option.description}
                    </p>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
      {hasRecurrence && (
        <div className="mb-6 flex flex-col gap-2 bg-gray-100 p-2">
          <div className="flex items-center gap-4">
            <div className="rounded-full bg-yellow-500 p-1.5 text-white">
              <Icon name="warningTriangle" className="h-6 w-6" />
            </div>
            <div className="text-sm">
              {t("planning:delete_modal.recurrence_warning", {
                defaultValue:
                  "The task you are attempting to delete is part of a recurring sequence.",
              })}
            </div>
          </div>
        </div>
      )}
      <div className="mb-6 flex flex-col gap-2 bg-gray-100 p-2">
        <div className="flex items-center gap-4">
          <div className="rounded-full bg-red-500 p-1.5 text-white">
            <Icon name="warningTriangle" className="h-6 w-6" />
          </div>
          <div className="text-sm">{t("planning:delete_modal.description")}</div>
        </div>
      </div>
      <DialogFooter
        primary={{
          label: `${t("common:delete")} ${hasRecurrence ? "" : `(${selectedOption.count})`}`,
          onClick: () => onSubmit(card, selectedOption.operation),
        }}
        onClose={onClose}
      />
    </div>
  );
}
