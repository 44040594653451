import { ChangePasswordOperationRequest, ChangePasswordRequest, User } from "@apacta/sdk";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useToasts } from "~/lib/toast/use-toasts";
import { useAPI } from "~/lib/api";
import TextInput, { InputType } from "~/lib/ui/form-elements/text-input";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import { captureException } from "~/lib/error-reporting";
import { useEffect, useState } from "react";
import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { getIcon } from "~/lib/ui";

export function ChangeUserPasswordModal({
  onClose,
  user,
}: {
  onClose: (userId?: string) => void;
  user: User;
}) {
  const { t } = useTranslation();
  const { show: addToast } = useToasts();
  const api = useAPI();
  // Password visibility
  const [visibility, setVisibility] = useState<boolean>(false);
  const [inputType, setInputType] = useState<InputType>("password");

  function toggleVisibility() {
    setVisibility(!visibility);
  }
  useEffect(() => {
    visibility ? setInputType("text") : setInputType("password");
  }, [visibility]);

  // Input fields
  const { parseBackendErrors, register, getValue } = useFormState({
    schema: {
      password: z.string(),
    },
    initialValues: {
      password: user.password,
    },
  });
  const changePassword = useMutation({
    mutationFn: (args: ChangePasswordOperationRequest) => {
      return api.changePassword(args);
    },
    onSuccess: () => {
      addToast({
        variant: "success",
        title: t("users:change_password_success_title"),
        description: t("users:change_password_success_description"),
        Icon: getIcon("successCircle"),
      });
    },
    onError: (error: Error) => {
      parseBackendErrors(error);
      addToast({
        variant: "error",
        title: t("users:change_password_error_title"),
        description: t("users:change_password_error_description"),
        Icon: getIcon("errorCircle"),
      });
      captureException(new Error("Cannot create customer", { cause: error }));
    },
    onSettled: () => {
      onClose();
    },
  });
  async function handleSubmit() {
    const requestParams: ChangePasswordRequest = {
      password: getValue("password"),
    };
    return changePassword.mutate({
      userId: user.id,
      changePasswordRequest: requestParams,
    });
  }

  return (
    <div>
      <DialogHeader
        title={t("users:change_password")}
        Icon={getIcon("password")}
        variant="warning"
      />
      <div className="mb-12">
        <div className="mt-2">
          <div className="flex flex-col gap-5">
            <TextInput
              hasButton={true}
              onIconClick={toggleVisibility}
              Icon={visibility ? getIcon("passwordShow") : getIcon("passwordHide")}
              {...register("password")}
              placeholder={t("common:change_password")}
              type={inputType}
              label={t("common:password")}
            />
          </div>
        </div>
      </div>
      <DialogFooter
        onClose={onClose}
        primary={{
          onClick: handleSubmit,
          label: t("common:confirm"),
        }}
      />
    </div>
  );
}
