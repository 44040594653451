import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigationContent } from "./components/navigation-item/navigation-item.content";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { NavigationItemMobile } from "./components/navigation-item/navigation-item-mobile";
import { getIcon, Icon } from "~/lib/ui";
import { twMerge } from "tailwind-merge";

export function SidebarMobile({
  isOpen,
  onDismiss,
  onOpenLanguageSelect,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  onOpenLanguageSelect: () => void;
}) {
  // Handle local visibility, used for animation states and cleanup
  const [localVisible, setLocalVisible] = useState(false);

  const { mobileNavigation } = useNavigationContent();
  const { t } = useTranslation();

  const location = useLocation();

  // Disable background scrolling when sidebar is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  });

  // Close sidebar when location changes
  useEffect(() => {
    handleClose();
  }, [location.pathname]);

  // Show sidebar when triggered
  useEffect(() => {
    if (isOpen) {
      setLocalVisible(true);
    }
  }, [isOpen]);

  const handleClose = () => {
    onDismiss();
  };

  // Temporary until topbar handles everything
  const languageNavigationItem = {
    name: t("ui:navigation.change_language", "Change language"),
    href: "#",
    icon: getIcon("language"),
  };

  // Temporary until topbar handles everything
  function showChangeLanguage() {
    handleClose();
    onOpenLanguageSelect();
  }

  return (
    <>
      {localVisible && (
        <div
          className={twMerge(
            "fixed bottom-0 left-0 right-0 top-0 z-20 bg-black/30",
            isOpen ? "animate-fade-in" : "animate-fade-out"
          )}
          onAnimationEnd={() => !isOpen && setLocalVisible(false)}
        >
          <div className="flex h-screen">
            <div
              className={twMerge(
                "flex h-screen w-4/5 flex-col bg-primary pt-4 text-white",
                isOpen ? "animate-enter-from-left" : "animate-exit-to-left"
              )}
            >
              <div className="flex flex-shrink-0 items-center px-4">
                <Link to="/">
                  <img className="h-12 w-auto" src="/logo.png" alt="Apacta logo" />
                </Link>
              </div>

              <nav className="scrollbar-hide mt-4 flex shrink flex-col justify-between space-y-1 overflow-y-auto px-2 py-2">
                {mobileNavigation.map((item) => (
                  <NavigationItemMobile item={item} key={item.name + item.featureName} />
                ))}

                <div>
                  <NavigationItemMobile
                    item={languageNavigationItem}
                    onClick={() => showChangeLanguage()}
                  />
                </div>
              </nav>
            </div>
            <div className="relative flex w-1/5 justify-center pt-4">
              <div
                className={twMerge(
                  "absolute flex h-12 w-12 items-center justify-center rounded-full bg-white text-zinc-700",
                  isOpen ? "animate-enter-from-right" : "animate-exit-to-right"
                )}
              >
                <Icon name="close" className="h-6 w-6" onClick={() => handleClose()} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
