import TextInput from "~/lib/ui/form-elements/text-input";
import { useTranslation } from "react-i18next";
import { Contact, EditContactRequest } from "@apacta/sdk";
import { Suspense } from "react";
import { useAPI } from "~/lib/api";
import { PageSpinner } from "~/lib/ui/page-spinner";
import ButtonLegacy from "~/lib/ui/buttons/button-legacy";
import { useParams } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToasts } from "~/lib/toast/use-toasts";
import { PaymentConditionSelection } from "~/pages/customers/_cmp/payment-condition-selection";
import { useFormState } from "~/lib/form-state";
import { customerSchema } from "../_cmp/customer-schema";
import { StreetAddressInput } from "~/lib/ui/street-address-input";
import { CVRInput } from "~/lib/ui/cvr-input";
import { usePageTitle } from "~/lib/navigation/use-page-title";
import { useOutletContext } from "react-router-dom";
import { ZipInput } from "~/lib/ui/zip-input";
import { useMount } from "~/lib/lifecycle-helpers";
import BlockNavigation from "~/lib/navigation/block-navigation";

export default function CustomerTab() {
  const pageTitle = usePageTitle();
  const { customer } = useOutletContext<{ customer: Contact }>();
  const api = useAPI();
  const { id: customerId } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    isPending,
    isValid,
    isModified,
    getValue,
    getValues,
    register,
    getField,
    parseBackendErrors, // TODO: Add this to error handling
    setValues,
    onChange,
    resetInitialValues,
  } = useFormState({
    schema: customerSchema,
    initialValues: {
      name: customer.name || "",
      address: customer.address ?? "",
      cityName: customer.cityName ?? "",
      email: customer.email ?? "",
      phone: customer.phone ?? "",
      cvr: customer.cvr ?? "",
      ean: customer.ean as string | undefined,
      zip_code: customer.zipCode ?? undefined,
      payment_term_id: customer.paymentTermId as string | undefined,
      cityId: customer.cityId ?? undefined,
    },
  });

  useMount(() => {
    pageTitle.set(`${customer?.name} | ${t("customers:tabs.primary_label", { count: 2 })}`);
  });

  const toast = useToasts();
  const customerEdit = useMutation({
    mutationFn: (args: EditContactRequest) => api.editContact(args),
    onSuccess: () => {
      toast.showTemplate("CHANGES_SAVED");
      queryClient.invalidateQueries({
        queryKey: ["customer", customerId],
      });
      resetInitialValues(getValues());
    },
  });

  const handleSaveCustomer = async () => {
    if (customerId) {
      await customerEdit.mutateAsync({
        contactId: customerId,
        contact: {
          id: customerId,
          address: getValue("address") ?? null,
          cvr: getValue("cvr")?.toString() ?? null,
          name: getValue("name"),
          zipCode: getValue("zip_code"),
          cityName: getValue("cityName"),
          email: getValue("email") ?? null,
          phone: getValue("phone")?.toString() ?? null,
          ean: getValue("ean")?.toString() ?? null,
          paymentTermId: getValue("payment_term_id") ?? null,
          // Why is this required?
          countryId: customer?.countryId ?? null,
          cityId: getValue("cityId") ?? null,
          description: customer?.description ?? null,
          phoneCountrycode: customer?.phoneCountrycode ?? null,
          website: customer?.website ?? null,
          companyId: customer?.companyId ?? null,
          erpId: customer?.erpId ?? null,
          tripletexId: customer?.tripletexId ?? null,
          centigaId: customer?.centigaId ?? null,
          pogoId: customer?.pogoId ?? null,
        },
      });
      await queryClient.invalidateQueries({
        queryKey: ["customer", customerId],
      });
    }
  };

  const zipToCityId = async (zipCode?: string): Promise<string | undefined> => {
    if (!zipCode) return undefined;
    return api.zipCodeLookup({ zipCode }).then((res) => res.data.id ?? undefined);
  };

  const navigationBlocked = isValid && !isPending && isModified;

  return (
    <>
      <BlockNavigation when={navigationBlocked} onSaveBeforeNavigate={handleSaveCustomer} />
      <div className="flex w-full flex-col gap-12">
        <Suspense
          fallback={
            <div className="relative h-96 w-full">
              <PageSpinner loadingMessage={t("common:loading")} />
            </div>
          }
        >
          <>
            <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
              <h2 className="m-0">{t("customers:tabs.primary_label")}</h2>
              <div>
                <ButtonLegacy
                  onClick={() => handleSaveCustomer()}
                  variant="tertiary"
                  disabled={!isValid || isPending || !isModified}
                >
                  {t("common:save_changes")}
                </ButtonLegacy>
              </div>
            </div>
            <div className="bg-white shadow sm:rounded-lg">
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="divide-y divide-gray-200">
                  <div className="py-4 sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5 lg:grid">
                    <dt className="flex items-center text-sm font-medium">{t("common:cvr")}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                      <CVRInput
                        onChange={(val) => onChange("cvr", val)}
                        value={getValue("cvr")}
                        onSelect={async (i) => {
                          setValues({
                            cvr: i.vat?.toString(),
                            name: i.name,
                            email: i.email,
                            phone: i.phone,
                            address: i.address,
                            cityName: i.city,
                            zip_code: i.zipcode.toString(),
                            cityId: await zipToCityId(i.zipcode),
                          });
                        }}
                      />
                    </dd>
                  </div>
                  <div className="py-4 sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5 lg:grid ">
                    <dt className="flex items-center text-sm font-medium">{t("common:name")}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                      <TextInput {...register("name")} />
                    </dd>
                  </div>
                  <div className="py-4 sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5 lg:grid ">
                    <dt className="flex items-center text-sm font-medium">{t("common:address")}</dt>
                    <dd className="mt-1 flex flex-col gap-4 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                      <StreetAddressInput
                        {...register("address")}
                        onSelect={async (item) => {
                          setValues({
                            address: item.addressWithNumber,
                            zip_code: item.zipCode,
                            cityName: item.cityName,
                            cityId: await zipToCityId(item.zipCode),
                          });
                        }}
                      />
                      <div className="sm:flex sm:gap-4">
                        <ZipInput
                          placeholder={t("common:zip_code")}
                          {...register("zip_code")}
                          onSelect={async (item) => {
                            setValues({
                              zip_code: item.zipCode,
                              cityName: item.cityName,
                              cityId: await zipToCityId(item.zipCode),
                            });
                          }}
                        />
                        <TextInput placeholder={t("common:city_name")} {...register("cityName")} />
                      </div>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5 lg:grid ">
                    <dt className="flex items-center text-sm font-medium">
                      {t("common:email_address")}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                      <TextInput placeholder={t("common:email_address")} {...register("email")} />
                    </dd>
                  </div>
                  <div className="py-4 sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5 lg:grid ">
                    <dt className="flex items-center  text-sm font-medium">
                      {t("common:phone_number")}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                      <TextInput placeholder={t("common:phone_number")} {...register("phone")} />
                    </dd>
                  </div>
                  <div className="py-4 sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5 lg:grid">
                    <dt className="flex items-center  text-sm font-medium">{t("common:ean")}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                      <TextInput placeholder={t("common:ean")} {...register("ean")} />
                    </dd>
                  </div>
                  <div className="py-4 sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5 lg:grid">
                    <dt className="flex items-center text-sm font-medium">
                      {t("common:terms_of_payment")}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3">
                      <PaymentConditionSelection
                        hideLabel={true}
                        onChange={(paymentTerm) =>
                          paymentTerm?.id && onChange("payment_term_id", paymentTerm.id)
                        }
                        initialConditionId={getValue("payment_term_id") || undefined}
                      />
                    </dd>
                  </div>
                  <div className="py-4 sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5 lg:grid">
                    <dt className="flex items-center text-sm font-medium">
                      {t("common:erp_id", "Customer number")}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-3">
                      <TextInput
                        defaultValue={customer?.erpId || ""}
                        disabled
                        label=""
                        name="ean"
                      />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </>
        </Suspense>
      </div>
    </>
  );
}
