import { toLocalDateString } from "~/lib/utils/date";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { sub } from "date-fns";
import { useEffect } from "react";

// Thin wrapper for search-params with a few helpers
export function useEmployeesParams() {
  const [employeesParams, setEmployeesParams] = useTypedSearchParams<{
    start_date?: string;
    end_date?: string;
    employeeIds?: Array<string>;
  }>();

  const defaultFromDate = sub(new Date(), { days: 14 });
  const defaultToDate = new Date();

  /** Set default date range to prevent fetching data for years in the past */
  useEffect(() => {
    if (!employeesParams.start_date && !employeesParams.end_date) {
      handlePeriodChange(defaultFromDate, defaultToDate);
    }
  }, [employeesParams]);

  function handlePeriodChange(start?: Date, end?: Date) {
    setEmployeesParams("start_date", start ? toLocalDateString(start) : undefined);
    setEmployeesParams("end_date", end ? toLocalDateString(end) : undefined);
  }

  function handleUnsetPeriod() {
    setEmployeesParams("start_date", undefined);
    setEmployeesParams("end_date", undefined);
  }

  function handleResetPeriod(): void {
    setEmployeesParams("start_date", toLocalDateString(defaultFromDate));
    setEmployeesParams("end_date", toLocalDateString(defaultToDate));
  }

  return {
    handlePeriodChange,
    setEmployeesParams,
    handleUnsetPeriod,
    handleResetPeriod,
    startDate: employeesParams.start_date,
    endDate: employeesParams.end_date,
    employeeIds: employeesParams.employeeIds,
  };
}
