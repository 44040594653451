import * as RadixDropdown from "@radix-ui/react-dropdown-menu";
import { ReactNode } from "react";
import { getButtonClasses } from "../buttons/shared/button.shared";
import { DropdownItemCheck } from "./item-check";
import { DropdownItemRadio } from "./item-radio";
import { DropdownItemText } from "./item-text";
import { PopoverAlign, PopoverSide } from "~/lib/ui/popover/popover";
import { twMerge } from "tailwind-merge";
import { Icon } from "~/lib/ui";

export function Dropdown({
  trigger = (
    <div role="button" tabIndex={0} className={getButtonClasses({ variant: "secondary" })}>
      <Icon name="menuKebab" className="h-6 w-6" />
    </div>
  ),
  children,
  defaultOpen = false,
  options,
  onOpenChange,
}: {
  trigger?: ReactNode;
  children: ReactNode;
  defaultOpen?: boolean;
  onOpenChange?: (newOpen: boolean) => void;
  options?: {
    root?: RadixDropdown.DropdownMenuProps;
    trigger?: RadixDropdown.DropdownMenuTriggerProps;
    content?: RadixDropdown.DropdownMenuContentProps;
    align?: {
      side?: PopoverSide;
      sideOffset?: number;
      align?: PopoverAlign;
      alignOffset?: number;
    };
  };
}) {
  const animationClasses = {
    top: "radix-side-top:animate-slide-up",
    right: "radix-side-right:animate-slide-right",
    bottom: "radix-side-bottom:animate-slide-down",
    left: "radix-side-left:animate-slide-left",
  };

  return (
    <RadixDropdown.Root {...options?.root} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
      <RadixDropdown.Trigger {...options?.trigger}>{trigger}</RadixDropdown.Trigger>
      <RadixDropdown.Portal>
        <RadixDropdown.Content
          {...options?.content}
          align={options?.align?.align ?? "center"}
          side={options?.align?.side ?? "bottom"}
          sideOffset={options?.align?.sideOffset ?? 10}
          alignOffset={options?.align?.alignOffset ?? 10}
          className={twMerge(
            animationClasses[options?.align?.side ?? "bottom"],
            "min-w-[100vw] overflow-hidden rounded-lg border bg-white text-lg shadow-sm highlighted:bg-shade-100 highlighted:text-white highlighted:outline-0 md:min-w-[10em] md:text-base"
          )}
        >
          {children}
        </RadixDropdown.Content>
      </RadixDropdown.Portal>
    </RadixDropdown.Root>
  );
}

Dropdown.Item = DropdownItemText;
Dropdown.Radio = DropdownItemRadio;
Dropdown.Check = DropdownItemCheck;
